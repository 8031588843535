/* ======================================================================= */
/* ================== >>> ICONS FONTS HEALTH 4 PET <<< =================== */
/* ======================================================================= */
$icomoon-font-path: "../fonts/" !default;

$icon-h4p-ambulatorial: "\e900";
$icon-h4p-hospitalar: "\e901";
$icon-h4p-reembolso: "\e902";
$icon-h4p-facebook: "\e903";
$icon-h4p-whatsapp: "\e905";
$icon-h4p-instagram: "\e907";
$icon-h4p-nome: "\e908";
$icon-h4p-telefone: "\e909";
$icon-h4p-email: "\e90a";
$icon-h4p-lupa: "\e90b";
$icon-h4p-digipet-1: "\e90c";
$icon-h4p-health-icone: "\e90d";
$icon-h4p-health-icone-circular: "\e90e";
$icon-h4p-farma4pet: "\e90f";
$icon-h4p-pethome-1: "\e910";
$icon-h4p-petclub-1: "\e911";
$icon-h4p-petfone-1: "\e912";
$icon-h4p-petfone-2: "\e913";
$icon-h4p-digipet-2: "\e914";
$icon-h4p-pethome-2: "\e915";
$icon-h4p-petclub-2: "\e916";
$icon-h4p-atendimento-ambulatorial: "\e917";
$icon-h4p-internacao: "\e918";
$icon-h4p-urgencia-emergencia: "\e919";
$icon-h4p-exames-imagem: "\e91a";
$icon-h4p-consulta-especialista: "\e91b";
$icon-h4p-castracao: "\e91c";
$icon-h4p-atendimento-hopitalar: "\e91d";
$icon-h4p-cirurgia: "\e91e";
$icon-h4p-exames-laboratoriais: "\e91f";
$icon-h4p-vacinas: "\e920";
$icon-h4p-microchip: "\e921";
$icon-h4p-reembolso-livre: "\e922";
$icon-h4p-limpeza-tartaro: "\e923";
$icon-h4p-petnutri: "\e904";
$icon-h4p-telefone-2: "\e924";

@font-face {
	font-family: 'Health4pet-Icons';
	src: url('#{$icomoon-font-path}Health4pet-Icons.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-h4p-"], [class*=" icon-h4p-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Health4pet-Icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin health4pet-icons {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Health4pet-Icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-h4p-ambulatorial {
	&:before {
		content: $icon-h4p-ambulatorial;
	}
}
.icon-h4p-hospitalar {
	&:before {
		content: $icon-h4p-hospitalar;
	}
}
.icon-h4p-reembolso {
	&:before {
		content: $icon-h4p-reembolso;
	}
}
.icon-h4p-facebook {
	&:before {
		content: $icon-h4p-facebook;
	}
}
.icon-h4p-whatsapp {
	&:before {
		content: $icon-h4p-whatsapp;
	}
}
.icon-h4p-instagram {
	&:before {
		content: $icon-h4p-instagram;
	}
}
.icon-h4p-nome {
	&:before {
		content: $icon-h4p-nome;
	}
}
.icon-h4p-telefone {
	&:before {
		content: $icon-h4p-telefone;
	}
}
.icon-h4p-email {
	&:before {
		content: $icon-h4p-email;
	}
}
.icon-h4p-lupa {
	&:before {
		content: $icon-h4p-lupa;
	}
}
.icon-h4p-digipet-1 {
	&:before {
		content: $icon-h4p-digipet-1;
	}
}
.icon-h4p-health-icone {
	&:before {
		content: $icon-h4p-health-icone;
	}
}
.icon-h4p-health-icone-circular {
	&:before {
		content: $icon-h4p-health-icone-circular;
	}
}
.icon-h4p-farma4pet {
	&:before {
		content: $icon-h4p-farma4pet;
	}
}
.icon-h4p-pethome-1 {
	&:before {
		content: $icon-h4p-pethome-1;
	}
}
.icon-h4p-petclub-1 {
	&:before {
		content: $icon-h4p-petclub-1;
	}
}
.icon-h4p-petfone-1 {
	&:before {
		content: $icon-h4p-petfone-1;
	}
}
.icon-h4p-petfone-2 {
	&:before {
		content: $icon-h4p-petfone-2;
	}
}
.icon-h4p-digipet-2 {
	&:before {
		content: $icon-h4p-digipet-2;
	}
}
.icon-h4p-pethome-2 {
	&:before {
		content: $icon-h4p-pethome-2;
	}
}
.icon-h4p-petclub-2 {
	&:before {
		content: $icon-h4p-petclub-2;
	}
}
.icon-h4p-atendimento-ambulatorial {
	&:before {
		content: $icon-h4p-atendimento-ambulatorial;
	}
}
.icon-h4p-internacao {
	&:before {
		content: $icon-h4p-internacao;
	}
}
.icon-h4p-urgencia-emergencia {
	&:before {
		content: $icon-h4p-urgencia-emergencia;
	}
}
.icon-h4p-exames-imagem {
	&:before {
		content: $icon-h4p-exames-imagem;
	}
}
.icon-h4p-consulta-especialista {
	&:before {
		content: $icon-h4p-consulta-especialista;
	}
}
.icon-h4p-castracao {
	&:before {
		content: $icon-h4p-castracao;
	}
}
.icon-h4p-atendimento-hopitalar {
	&:before {
		content: $icon-h4p-atendimento-hopitalar;
	}
}
.icon-h4p-cirurgia {
	&:before {
		content: $icon-h4p-cirurgia;
	}
}
.icon-h4p-exames-laboratoriais {
	&:before {
		content: $icon-h4p-exames-laboratoriais;
	}
}
.icon-h4p-vacinas {
	&:before {
		content: $icon-h4p-vacinas;
	}
}
.icon-h4p-microchip {
	&:before {
		content: $icon-h4p-microchip;
	}
}
.icon-h4p-reembolso-livre {
	&:before {
		content: $icon-h4p-reembolso-livre;
	}
}
.icon-h4p-limpeza-tartaro {
	&:before {
		content: $icon-h4p-limpeza-tartaro;
	}
}
.icon-h4p-petnutri {
	&:before {
		content: $icon-h4p-petnutri;
	}
}
.icon-h4p-telefone-2 {
	&:before {
		content: $icon-h4p-telefone-2;
	}
}