@import "variables";
@import "mixins";
@import "icons-fonts-health4pet";
@import "../components/bootstrap/scss/functions";
@import "../components/bootstrap/scss/variables";
@import "../components/bootstrap/scss/mixins";

/* =======================================================================
   FONT EXTERNAL IMPORT
   ======================================================================= */
@include fontes('TitilliumWeb-ExtraLight', 'Titillium Web', 200, normal);
@include fontes('TitilliumWeb-Light', 'Titillium Web', 300, normal);
@include fontes('TitilliumWeb-Regular', 'Titillium Web', 400, normal);
@include fontes('TitilliumWeb-SemiBold', 'Titillium Web', 600, normal);
@include fontes('TitilliumWeb-Bold', 'Titillium Web', 700, normal);
@include fontes('TitilliumWeb-Black', 'Titillium Web', 900, normal);

/* =======================================================================
   TAGS AND CLASS
   ======================================================================= */
html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: inherit;
	color: $cor_text;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	-webkit-text-stroke: 1px transparent;
	padding-top: 70px;
	@include media-breakpoint-down(md) {
		padding-top: 15vw;
	}

	@media (min-width: 1280px) and (max-width: 1300px) {
		padding-top: 65px;
	}
}

a {
	text-decoration: none;
}

input {
	&:focus {
		outline: none;
	}
}

button {
	cursor: pointer;
	border: none;
}

@include media_screen_min($screen_desktop_sm) {
	.container {
		max-width: 1240px !important;
	}
}

@include media_screen_min($screen_desktop_md) {
	.container {
		max-width: 1320px !important;
	}
}

@include media_screen_min($screen_desktop_lg) {
	.container {
		max-width: 1400px !important;
	}
}

@-webkit-keyframes btnwhats {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #c6fe00, 0 0 70px #c6fe00, 0 0 80px #c6fe00, 0 0 100px #c6fe00, 0 0 150px #c6fe00;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #c6fe00, 0 0 35px #c6fe00, 0 0 40px #c6fe00, 0 0 50px #c6fe00, 0 0 75px #c6fe00;
	}
}

@-webkit-keyframes neon12 {
	from {
		box-shadow: 0 0 10px #fff, 0 0 40px $cor_07, 0 0 50px $cor_07, 0 0 60px $cor_07, 0 0 70px $cor_07, 0 0 80px $cor_07;
	}
	to {
		box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $cor_07, 0 0 25px $cor_07, 0 0 30px $cor_07, 0 0 35px $cor_07, 0 0 40px $cor_07;
	}
}

/* =======================================================================
   HEADER
   ======================================================================= */
header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	background: #ffffff;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

	.logo {
	}

	img {
		max-width: 400px;
	}

	nav {
		a.nav-link {
			color: $cor_text;

			&:hover {
				color: $cor_02;
			}

			&.active {
				background: none !important;
				color: $cor_13;
				font-weight: 600;
			}

			@include media-breakpoint-down(sm) {
				padding-bottom: 15px;
				padding-top: 15px;
			}
		}

		.collapse {
			flex-grow: unset;
			margin-left: auto;
		}
	}

	.whatsapp-header {
		-webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
	}

	.hire-top {
		a {
			display: block;
			width: 150px;
			padding: 5px 5px;
			font-size: 1.3em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			text-decoration: none;
			background: $cor_18;
			border-bottom: 4px solid darken($cor_18, 10);
			@include animado();
			@include media-breakpoint-down(sm) {
				display: none;
			}

			img {
				max-width: 25px;
			}

			&:hover {
				border-radius: 0;
				background: darken($cor_18, 10);
			}
		}
	}

	.phone-top {

		text-align: center;
		//background: $cor_01;
		color: $cor_text;
		font-size: 1.3vw;
		@media screen and (min-width: 1400px) {
			font-size: 1.4em;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}

		a {
			span {
				font-size: .5em;
			}

			font-weight: 600;
			display: block;
			color: $cor_13;
			margin-top: -3px;
			font-size: 1.2em;

			&:hover {
				text-decoration: none;
			}
		}

	}

	.whatsapp-top {
		font-weight: 700;
		text-align: center;
		color: darken(#4da735, 10);
		font-size: 1.5em;
		padding-top: 25px;
		padding-bottom: 10px;
		@include media-breakpoint-down(sm) {
			display: none;
		}

		a {
			span {
				font-size: .5em;
			}

			i {
				font-size: 0.8em;
			}

			color: #4da735;
			margin-top: -8px;
			font-size: 1.4em;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

/* =======================================================================
   BANNER
   ======================================================================= */
section.banner {
	position: relative;
	padding-top: 40px;

	&:after {
		content: "";
		background-image: url("../images/banners/bg-banner-parallax-01.jpg");
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.5;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	//background-image: url("../images/banners/banner-black.png"), url("../images/banners/bg-banner-parallax-01.jpg");
	//background-attachment: local, fixed;
	//background-position: center top, center;
	//background-repeat: no-repeat;
	//background-size: auto, cover;
	//padding-top: 240px;

	@include media-breakpoint-down(sm) {
		background-image: url("../images/banners/bg-banner-parallax-01.jpg");
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 25px;
		.banner-black-friday {
			margin: 0 -15px;
		}
	}
	text-align: center;

	.text-banner {
		color: #ffffff;
		background: $cor_18;
		border: darken($cor_18, 10) solid 3px;
		border-left: none;
		border-right: none;
		text-align: center;

		p {
			padding: 20px 0;
			margin: 0;
			font-weight: 600;
			text-shadow: none;
		}
	}

	.action-banner {
		background: $cor_19;
		border-bottom: 4px solid darken($cor_19, 10);

		a {
			display: block;
			width: 100%;
			margin: 0 auto;
			padding: 10px 5px;
			font-size: 1.7em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			text-decoration: none;

			@include animado();

			img {
				max-width: 30px;
				margin-right: 5px;
				vertical-align: sub;
			}

			&:hover {
				border-radius: 0;
			}
		}
	}

	.img-banner {
		width: 800px;
	}

	h2 {
		font-size: 3em;
		font-weight: 700;
		margin-bottom: 20px;
		color: $cor_13;
		text-shadow: -5px -5px 0 #fff, 5px -5px 0 #fff, -5px 5px 0 #fff, 5px 5px 0 #fff;
		@include media-breakpoint-down(sm) {
			font-size: 8vw;
		}

	}

	p {
		font-size: 1.8em;
		line-height: 1.4em;
		font-weight: 400;
		margin-bottom: 30px;
		text-shadow: -5px -5px 0 #fff, 5px -5px 0 #fff, -5px 5px 0 #fff, 5px 5px 0 #fff;
		@include media-breakpoint-down(sm) {
			font-size: 5vw;
			font-weight: 600;
		}
	}

	a.link-banner {
		color: #ffffff;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
		font-size: 2em;
		font-weight: 700;
		background: #FF7100;
		display: inline-block;
		padding: 6px 30px;
		margin-bottom: 30px;
		text-decoration: none;
		@include animado();

		&:hover {
			background: $cor_07;
			text-decoration: none;
			color: #ffffff;
		}

		@include media-breakpoint-down(sm) {
			font-size: 6vw;
		}
	}

	.form-inside-banner {
		background: $cor_13;
		border: darken($cor_13, 10) solid 3px;
		border-radius: 10px;
		padding: 20px 0;

		&.black-friday {
			background: #000;
			border: darken($cor_18, 10) solid 3px;
		}

		@include media-breakpoint-down(sm) {
			background-image: none;
			img {
				margin-top: 20px;
			}
			margin-bottom: 20px;
		}

		form {
			position: relative;

			@include placeholder {
				font-weight: 500;
				font-size: 18px;
				color: #aaa;
			}

			.spotlight {
				text-align: center;

				h3 {
					color: #fff;
					font-size: 1.6em;
					font-weight: 700;
					@include media-breakpoint-down(sm) {
						font-size: 10vw;
					}
				}

				h4 {
					color: #fff;
					font-size: 1.1em;
					font-weight: 700;
					padding: 0 10px 10px 10px;
					@include media-breakpoint-down(sm) {
						font-size: 8.6vw;
					}
				}

				h5 {
					color: #fff;
					font-size: 1.1em;
					font-weight: 700;
					margin-bottom: 20px;
					@include media-breakpoint-down(sm) {
						font-size: 8.6vw;
					}
				}
			}

			h4 {
				color: #fff;
				font-weight: 600;
			}

			input[type="text"] {
				width: 90%;
				margin: 10px auto;
				height: 50px;
				border: #ccc solid 2px;
				padding-left: 35px;
				position: relative;
				display: block;
				border-radius: 5px;

				@each $name in 'nome' 'telefone' 'email' 'cep' {
					&[name="#{$name}"] {
						background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
						background-size: 26px;
					}
				}

				&:focus {
					border: $cor_02 solid 2px;
				}

				@include media-breakpoint-down(sm) {
					display: block;
					width: 80%;
				}
			}

			.inputGroup {
				background-color: #fff;
				display: block;
				margin: 10px 0;
				position: relative;

				label {
					padding: 12px 30px 12px 50px;
					width: 100%;
					display: block;
					text-align: left;
					color: #3C454C;
					cursor: pointer;
					position: relative;
					z-index: 2;
					transition: color 200ms ease-in;
					overflow: hidden;
					font-weight: 600;

					&:before {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%) scale3d(1, 1, 1);
						transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
						opacity: 0;
						z-index: -1;
					}

					&:after {
						width: 32px;
						height: 32px;
						content: '';
						border: 2px solid #D1D7DC;
						background-color: #fff;
						background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
						background-repeat: no-repeat;
						background-position: 2px 3px;
						border-radius: 50%;
						z-index: 2;
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						cursor: pointer;
						transition: all 200ms ease-in;
					}
				}

				input:checked ~ label {

					&:before {
						transform: translate(-50%, -50%) scale3d(56, 56, 1);
						opacity: 1;
					}

					&:after {
						background-color: #54E0C7;
						border-color: #54E0C7;
					}
				}

				input {
					width: 32px;
					height: 32px;
					order: 1;
					z-index: 2;
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;
					visibility: hidden;
				}
			}


			button {
				background: $cor_18;
				font-weight: 700;
				color: #fff;
				padding: 10px 40px;
				display: block;
				margin: 10px auto 0 auto;
				// text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
				font-size: 1.8em;
				@include animado();

				&:hover {
					background: $cor_08;
					text-decoration: none;
					color: #ffffff;
				}

				@include media-breakpoint-down(sm) {
					font-size: 7vw;
					margin-top: 20px;
				}
			}
		}
	}

	// PROJETO B
	.project-b & .form-inside-banner {
		background: $cor_01;
		border: darken($cor_01, 10) solid 3px;
		border-radius: 10px;
		padding: 20px 0;

		@include media-breakpoint-down(sm) {
			background-image: none;
			img {
				margin-top: 20px;
			}
			margin-bottom: 20px;
		}

		form {
			position: relative;

			@include placeholder {
				font-weight: 500;
				font-size: 18px;
				color: #aaa;
			}

			.spotlight {
				text-align: center;

				h3 {
					color: #fff;
					font-size: 2.1em;
					font-weight: 700;
					@include media-breakpoint-down(sm) {
						font-size: 10vw;
					}
				}

				h4 {
					color: #fff;
					font-size: 1.8em;
					font-weight: 700;
					margin-bottom: 20px;
					@include media-breakpoint-down(sm) {
						font-size: 8.6vw;
					}
				}
			}

			h4 {
				color: #fff;
				font-weight: 600;
			}

			input[type="text"] {
				width: 90%;
				margin: 10px auto;
				height: 50px;
				border: #ccc solid 2px;
				padding-left: 35px;
				position: relative;
				display: block;
				border-radius: 5px;

				@each $name in 'nome' 'telefone' 'email' 'cep' {
					&[name="#{$name}"] {
						background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
						background-size: 26px;
					}
				}

				&:focus {
					border: $cor_02 solid 2px;
				}

				@include media-breakpoint-down(sm) {
					display: block;
					width: 80%;
				}
			}

			.inputGroup {
				background-color: #fff;
				display: block;
				margin: 10px 0;
				position: relative;

				label {
					padding: 12px 30px 12px 50px;
					width: 100%;
					display: block;
					text-align: left;
					color: #3C454C;
					cursor: pointer;
					position: relative;
					z-index: 2;
					transition: color 200ms ease-in;
					overflow: hidden;
					font-weight: 600;

					&:before {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%) scale3d(1, 1, 1);
						transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
						opacity: 0;
						z-index: -1;
					}

					&:after {
						width: 32px;
						height: 32px;
						content: '';
						border: 2px solid #D1D7DC;
						background-color: #fff;
						background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
						background-repeat: no-repeat;
						background-position: 2px 3px;
						border-radius: 50%;
						z-index: 2;
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						cursor: pointer;
						transition: all 200ms ease-in;
					}
				}

				input:checked ~ label {

					&:before {
						transform: translate(-50%, -50%) scale3d(56, 56, 1);
						opacity: 1;
					}

					&:after {
						background-color: #54E0C7;
						border-color: #54E0C7;
					}
				}

				input {
					width: 32px;
					height: 32px;
					order: 1;
					z-index: 2;
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;
					visibility: hidden;
				}
			}


			button {
				background: $cor_11;
				font-weight: 700;
				color: #fff;
				padding: 10px 40px;
				display: block;
				margin: 10px auto 0 auto;
				border-radius: 30px;
				// text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
				font-size: 1.8em;
				@include animado();

				&:hover {
					background: $cor_09;
					text-decoration: none;
					color: #ffffff;
				}

				@include media-breakpoint-down(sm) {
					font-size: 7vw;
					margin-top: 20px;
				}
			}
		}
	}

	// BLACK FRIDAY
	&.black-friday {
		padding-top: 20px;
		margin-top: -5px;
		$cor_neon1: #DFFFFF;

		&:after {
			content: "";
			background-image: none;
			background: #000424;
			opacity: 1;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: -1;
		}

		.banner-text {

			h2 {
				font-size: 2.2em;
				font-weight: 700;
				margin-bottom: 10px;
				color: #F300A0;
				@include media-breakpoint-down(sm) {
					font-size: 8vw;
				}
			}

			p {
				font-size: 1em;
				line-height: 1.4em;
				font-weight: 500;
				margin-bottom: 10px;
				color: #fff;
				@include media-breakpoint-down(sm) {
					font-size: 5vw;
					font-weight: 600;
				}
			}

		}

		p {
			font-size: 1.8em;
			line-height: 1.4em;
			font-weight: 400;
			margin-bottom: 30px;
			@include media-breakpoint-down(sm) {
				font-size: 5vw;
				font-weight: 600;
			}
		}

		.text-banner {
			color: #ffffff;
			background: $cor_01;
			border: darken($cor_01, 10) solid 3px;
			border-left: none;
			border-right: none;
			text-align: center;

			p {
				padding: 20px 0;
				margin: 0;
				font-weight: 600;
				text-shadow: none;
			}
		}

		.action-banner {
			background: $cor_19;
			border-bottom: 4px solid darken($cor_19, 10);

			a {
				display: block;
				width: 100%;
				margin: 0 auto;
				padding: 10px 5px;
				font-size: 1.7em;
				font-weight: 700;
				text-align: center;
				color: #fff;
				text-decoration: none;

				@include animado();

				img {
					max-width: 30px;
					margin-right: 5px;
					vertical-align: sub;
				}

				&:hover {
					border-radius: 0;
				}
			}
		}

		.img-banner {
			width: 800px;
		}

		a.link-banner {
			@include media-breakpoint-down(md) {
				display: none;
			}
			color: #ffffff;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
			font-size: 1.3em;
			margin-top: 10px;
			font-weight: 700;
			background: #FF7100;
			display: inline-block;
			padding: 4px 30px;
			border-radius: 40px;
			margin-bottom: 30px;
			text-decoration: none;
			@include animado();

			&:hover {
				background: $cor_07;
				text-decoration: none;
				color: #ffffff;
			}

			@include media-breakpoint-down(sm) {
				font-size: 6vw;
				border-radius: 50px;
			}
		}

		@-webkit-keyframes neon_glow1 {

			from {
				text-shadow: 0 0 10px #fff,
				0 0 20px #fff,
				0 0 30px #fff,
				0 0 40px $cor_neon1,
				0 0 70px $cor_neon1,
				0 0 80px $cor_neon1,
				0 0 100px $cor_neon1,
				0 0 150px $cor_neon1;
			}
			to {
				text-shadow: 0 0 5px #fff,
				0 0 10px #fff,
				0 0 15px #fff,
				0 0 20px $cor_neon1,
				0 0 35px $cor_neon1,
				0 0 40px $cor_neon1,
				0 0 50px $cor_neon1,
				0 0 75px $cor_neon1;
			}
		}

		@-webkit-keyframes neon2 {
			from {
				text-shadow: 0 0 10px #fff,
				0 0 20px #fff,
				0 0 30px #fff,
				0 0 40px #228DFF,
				0 0 70px #228DFF,
				0 0 80px #228DFF,
				0 0 100px #228DFF,
				0 0 150px #228DFF;
			}
			to {
				text-shadow: 0 0 5px #fff,
				0 0 10px #fff,
				0 0 15px #fff,
				0 0 20px #228DFF,
				0 0 35px #228DFF,
				0 0 40px #228DFF,
				0 0 50px #228DFF,
				0 0 75px #228DFF;
			}
		}
	}
}

.form-banner {
	background: #f4f4f4;
	background-image: url("../images/banners/bg-form.png"), url("../images/banners/bg-form.png");
	background-position: left center, right center;
	background-repeat: no-repeat;
	border-top: #d1d1d1 solid 3px;
	border-bottom: #d1d1d1 solid 3px;
	padding: 20px 0;

	@include media-breakpoint-down(sm) {
		background-image: none;
		img {
			margin-top: 20px;
		}
	}

	form {
		position: relative;

		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #aaa;
		}

		.spotlight {
			text-align: center;

			h3 {
				color: $cor_14;
				text-transform: uppercase;
				font-size: 3em;
				font-weight: 700;
				@include media-breakpoint-down(sm) {
					font-size: 7vw;
				}
			}

			h4 {
				color: $cor_14;
				text-transform: uppercase;
				font-size: 2.5em;
				font-weight: 700;
				margin-bottom: 20px;
				@include media-breakpoint-down(sm) {
					font-size: 5vw;
				}
			}

			h5 {
				color: $cor_14;
				text-transform: uppercase;
				font-size: 3em;
				font-weight: 700;
				@include media-breakpoint-down(sm) {
					font-size: 4vw;
				}
			}
		}

		input {
			width: 250px;
			margin: 5px 0;
			height: 50px;
			border: #ccc solid 2px;
			padding-left: 35px;
			position: relative;
			display: inline-block;
			border-radius: 5px;

			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_02 solid 2px;
			}

			&[name="nome"] {
				margin-right: 20px;
				@include media-breakpoint-down(sm) {
					margin: 0 auto 20px auto;
				}
			}

			@include media-breakpoint-down(sm) {
				display: block;
				margin: 0 auto;
				width: 80%;
			}
		}

		h4 {
			font-weight: 600;
		}

		.inputGroup {
			background-color: #fff;
			display: block;
			margin: 10px 0;
			position: relative;

			label {
				padding: 12px 30px 12px 50px;
				width: 100%;
				display: block;
				text-align: left;
				color: #3C454C;
				cursor: pointer;
				position: relative;
				z-index: 2;
				transition: color 200ms ease-in;
				overflow: hidden;
				font-weight: 600;

				&:before {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%) scale3d(1, 1, 1);
					transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
					opacity: 0;
					z-index: -1;
				}

				&:after {
					width: 32px;
					height: 32px;
					content: '';
					border: 2px solid #D1D7DC;
					background-color: #fff;
					background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
					background-repeat: no-repeat;
					background-position: 2px 3px;
					border-radius: 50%;
					z-index: 2;
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;
					transition: all 200ms ease-in;
				}
			}

			input:checked ~ label {
				&:before {
					transform: translate(-50%, -50%) scale3d(56, 56, 1);
					opacity: 1;
				}

				&:after {
					background-color: #54E0C7;
					border-color: #54E0C7;
				}
			}

			input {
				width: 32px;
				height: 32px;
				order: 1;
				z-index: 2;
				position: absolute;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				visibility: hidden;
			}
		}

		button {
			background: $cor_07;
			font-weight: 700;
			color: #fff;
			padding: 10px 40px;
			display: block;
			margin: 10px auto 0 auto;
			border-radius: 30px;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
			font-size: 1.8em;
			@include animado();

			&:hover {
				background: $cor_01;
				text-decoration: none;
				color: #ffffff;
			}

			@include media-breakpoint-down(sm) {
				font-size: 7vw;
				margin-top: 20px;
			}
		}
	}
}

.text-form-banner {
	padding-top: 10px;
	text-align: center;

	p {
		font-size: 0.8em !important;
		margin-bottom: 0 !important;
	}
}

section.banner-mobile {
	background: $cor_15;

	@include media-breakpoint-up(md) {
		display: none;
	}

	h1, h2 {
		display: block;
		font-weight: 700;
		text-align: center;
		color: #fff;
		font-size: 2em;
		padding-top: 20px;

		span {
			display: block;
		}

		@include media-breakpoint-down(sm) {
			font-size: 7vw;
		}

	}

	h3 {
		display: block;
		font-weight: 700;
		text-align: center;
		color: #fff;
		font-size: 1.5em;
		padding-top: 5px;

		span {
			display: block;
		}

		@include media-breakpoint-down(sm) {
			font-size: 5vw;
		}

	}

	.know-ideal-health-plan {
		h2 {
			font-weight: 700;
			margin-bottom: 20px;
			font-size: 1.5em;
			@include media-breakpoint-down(sm) {
				font-size: 5vw;
			}
		}

		ul {
			padding-left: 10px;
			margin-bottom: 30px;
			text-align: center;
			margin-top: 20px;

			li {
				font-weight: 600;
				list-style: none;
				//padding-left: 25px;
				font-size: 1.1em;
				color: #ffffff;
				//background-image: url("../images/icon-check.svg");
				//background-repeat: no-repeat;
				//background-size: 20px;
				margin-bottom: 10px;

				img {
					width: 20px;
					vertical-align: top;
				}
			}
		}
	}

	p {
		text-align: center;
		font-size: 1.5em;
		padding: 0;
		@include media-breakpoint-down(sm) {
			font-size: 4vw;
			line-height: 5vw;
			padding: 0 0 10px 0;
		}
	}

	form {
		background: $cor_02;
		padding: 20px 20px;
		position: relative;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		h3 {
			font-weight: 700;
			color: #fff;
			text-align: center;
			font-size: 1.8em;
			line-height: 1em;
		}

		h4 {
			font-weight: 700;
			color: $cor_10;
			text-align: center;
			margin-bottom: 20px;
			font-size: 2em;
		}

		.spotlight {
			text-align: center;

			h4 {
				background: $cor_10;
				color: #fff;
				line-height: 1.4em;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 10px;
				font-size: 1.3em;
				border-radius: 10px;

				span {
					display: block;
					font-size: 1.4em;
					margin-bottom: 10px;
				}

				em {
					font-style: normal;
					font-size: 1.2em;
					display: block;
				}
			}

			h5 {
				color: $cor_10;
				font-size: 1.8em;
				font-weight: 700;
				//border-bottom: 3px solid #ffffff;
				padding-bottom: 10px;
				margin-bottom: 0;
				margin-top: 20px;
				text-transform: uppercase;
			}

			p {
				color: #ffffff;
				padding-bottom: 5px;
			}
		}

		input {
			width: 100%;
			margin: 5px 0;
			height: 50px;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			border-radius: 5px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_02 solid 1px;
			}
		}

		button {
			background: $cor_11;
			font-size: 1.7em;
			font-weight: 700;
			color: $cor_10;
			padding: 5px 0;
			width: 90%;
			display: block;
			margin: 5px auto 0 auto;
			border-radius: 5px;
			@include animado();

			&:hover {
				background: $cor_16;
				text-decoration: none;
				padding: 10px 40px;
			}
		}

		img {
			display: inline-block;
			position: relative;
			bottom: -20px;
		}
	}
}

.phone-mobile {
	@include media-breakpoint-up(sm) {
		display: none;
	}

	text-align: center;
	padding: 5px 0;

	h2 {
		margin: 0 5px;
		padding: 10px 0 !important;
		background: $cor_13;
		font-weight: 700;
		font-size: 4.5vw !important;
		line-height: 1.5em;
		border-radius: 50px;


		a {
			color: #fff;
			white-space: nowrap;

			span {
				display: inline !important;
			}

			i {
				background: $cor_13;
				color: #fff;
				border-radius: 100%;
				padding: 6px;
				font-size: 1em;
				line-height: 1em;
				vertical-align: unset;
			}
		}
	}
}

.whatsapp-float {
	text-align: center;
	padding: 5px 0;
	position: fixed;
	bottom: 15px;
	left: 15px;
	z-index: 999;
	display: flex;
	flex-wrap: wrap;

	.whatsapp-icone {
		font-weight: 700;
		font-size: 8vw;
		line-height: 1.5em;
		border-radius: 100%;

		@include media-breakpoint-up(sm) {
			font-size: 60px;
		}

		a {
			color: #fff;
			text-decoration: none;

			span {
				display: inline !important;
			}

			i {
				background: #4da735;
				color: #fff;
				border-radius: 100%;
				padding: 20px;
				font-size: 1em;
				line-height: 1em;
				vertical-align: unset;
			}
		}
	}

	.whatsapp-form {
		position: absolute;
		display: none;
		bottom: 50px;
		left: 90px;
		width: 250px;
		border: 2px solid #008a7c;
		margin-left: 20px;
		background: url('../images/bg-whatsapp.jpg') repeat;
		border-radius: 10px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
		overflow: hidden;
		@include media-breakpoint-down(sm) {
			bottom: 20px;
			left: 60px;
			width: 220px;
		}

		h2 {
			font-size: 1.5em;
			padding: 5px 0;
			background: #008a7c;
			color: #ffffff;
		}

		form {
			padding: 10px;

			.message {
				background: #fff;
				border-radius: 0px 5px 5px 5px;
				margin-bottom: 10px;

				&:after {
					border-width: 0px 10px 10px 0;
					border-color: transparent #fff transparent transparent;
					top: 0;
					left: -10px;
				}

				input {
					border: 1px #008a7c solid;
					border-radius: 0px 5px 5px 5px;
					width: 100%;
					padding: 5px 5px 5px 10px;
				}
			}

			button {
				background: #008a7c;
				color: #ffffff;
				padding: 5px 15px;
				border-radius: 20px;
				position: relative;
				z-index: 1;
				font-weight: 600;

				img {
					width: 20px;
					margin-left: 10px;
					margin-top: -3px;
				}
			}


		}
	}
}

/* =======================================================================
   CALL TO ACTION TOP
   ======================================================================= */
.call-to-action-top {
	background: $cor_13;
	padding: 20px 0;
	text-align: center;
	color: #fff;
	position: relative;

	.page-agradecimento & {
		padding: 150px 0;

		&:after {
			display: none;
		}
	}

	.call-to-action-top-warp {
		h1 {
			font-weight: 700;
		}

		p {
			font-size: 1.8em;
		}
	}
}

/* =======================================================================
   PLANS
   ======================================================================= */
.plans {
	padding: 40px 0;
	@include media-breakpoint-down(sm) {
		padding: 10px 0;
	}


	@include media-breakpoint-down(sm) {
		.plan-title {
			h1 {
				font-size: 9vw !important;
			}

			h3 {
				display: block;
				font-weight: 700;
				text-align: center;
				color: $cor_text !important;
				font-size: 7vw !important;
				padding-top: 20px;

				@include media-breakpoint-down(sm) {
					font-size: 10vw;
				}
			}
		}
	}

	h2 {
		display: block;
		font-weight: 700;
		text-align: center;
		color: $cor_14;
		font-size: 3em;
		padding-top: 20px;

		span {
			display: block;
		}

		@include media-breakpoint-down(sm) {
			font-size: 10vw;
		}
	}


	h1 {
		display: block;
		font-weight: 700;
		text-align: center;
		color: $cor_13;
		font-size: 3em;

		span {
			display: block;
		}

		@include media-breakpoint-down(sm) {
			font-size: 10vw;
		}

	}

	h3 {
		display: block;
		font-weight: 400;
		text-align: center;
		color: $cor_text;
		font-size: 1.5em;
		padding-top: 5px;

		span {
			color: $cor_01;
			font-weight: 600;

			&.fz-small {
				font-size: 0.5em;
				display: block;
				padding: 5px 0;
			}
		}

		@include media-breakpoint-down(sm) {
			font-size: 5vw;
		}

	}

	.know-ideal-health-plan {
		h2 {
			font-weight: 700;
			margin-bottom: 20px;
		}

		h3 {
			display: block;
			font-weight: 700;
			text-align: center;
			color: $cor_01 !important;
			font-size: 1.8em !important;
			padding-top: 20px;

			span {
				display: block;
			}

			@include media-breakpoint-down(sm) {
				font-size: 10vw;
			}
		}

		ul {
			padding-left: 10px;
			column-count: 3;

			li {
				font-weight: 600;
				list-style: none;
				padding-left: 25px;
				font-size: 1.3em;
				background-image: url("../images/icon-check.svg");
				background-repeat: no-repeat;
				background-size: 20px;
				margin-bottom: 10px;

				img {
					width: 20px;
					vertical-align: top;
				}
			}
		}
	}

	h5 {
		font-weight: 700;
		text-align: center;
		font-size: 1.8em;
		margin-top: 50px;


		@include media-breakpoint-down(sm) {
			font-size: 1.8em;
		}

		a {
			text-align: center;
			margin: 10px auto 0 auto;
			text-decoration: none;
			color: #fff;
			background: $cor_01;
			display: inline-block;
			padding: 10px 40px;
			border-radius: 100px;
			@include animado();

			&:hover {
				background: $cor_09;
			}
		}

	}

	p {
		text-align: center;
		font-size: 1.3em;
		padding: 0;
		@include media-breakpoint-down(sm) {
			font-size: 4vw;
			line-height: 5vw;
			padding: 0 0 20px 0;
		}
	}

	.plan {
		background: #ffffff;
		-webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
		position: relative;
		border-bottom: 5px solid;
		padding-bottom: 30px;
		margin-bottom: 20px;
		margin-top: 20px;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;

		@include media-breakpoint-down(sm) {
			margin: 0 0 40px 0;
		}


		p {
			font-size: 1em;
		}

		$cores_planos: (basic, $cor_11, darken($cor_11, 10)),
				(light, $cor_03, $cor_04),
				(plus, $cor_05, $cor_06),
				(total, $cor_07, $cor_08),
				(premium, $cor_09, $cor_10);
		@each $name_plan, $color_01, $color_02 in $cores_planos {

			&.#{$name_plan} {
				border-bottom-color: #{$color_02};

				.plan-top {
					.plan-bar-color {
						background: #{$color_01};
						height: 5px;
						border-radius: 10px;
					}

					h2 {
						color: #{$color_01};
					}
				}

				.plan-pet-icon {
					div.multiple-circles {
						background: #{$color_02};
						color: #{$color_02};
						@include media-breakpoint-down(sm) {
							background: none;
						}
					}

					.plan-discount {
						h6 {
							color: #{$color_02};
						}
					}
				}

				.plan-benefits-title {
					h3 {
						color: #{$color_01};
					}
				}

				.plan-price-complete {
					color: #{$color_01};
				}

				.plan-discount {
					background: #{$color_01};

					&:hover {
						background: #{$color_02};
					}
				}

				.plan-call-to-action {
					a {
						background: #{$color_01};
						border-bottom: 4px solid #{$color_02};

						&:hover {
							border-bottom-color: #{$color_02};
							background: darken($color_01, 10%);
						}
					}
				}

				.plan-info strong {
					color: #{$color_01};
				}

				.plan-info-action {
					color: #ffffff;
					text-align: center;
					background: #{$color_01};
					font-weight: 600;
					padding: 5px 0;
					font-size: 1.2em;
					margin-right: -15px;
					margin-bottom: 10px;
				}
			}
		}

		.plan-top {
			position: relative;

			h2 {
				text-align: center;
				font-weight: 700;
				font-size: 2em;
				padding-top: 20px;
				@include media-breakpoint-down(sm) {
					font-size: 6vw;
				}
			}
		}

		.best-seller-title {
			position: absolute;
			width: 190px;
			margin-left: calc(50% - 95px);
			text-align: center;
			top: -35px;
			padding: 10px 0 10px 0;
			background: $cor_07;
			font-size: 1em;
			color: #ffffff;
			font-weight: 700;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin: 0 auto;
			}
		}

		.plan-pet-icon {
			text-align: center;
			position: relative;

			.multiple-circles {
				border-radius: 100%;
				position: absolute;
				z-index: 14;
				left: 20px;
				top: -50px;
				padding: 2px;
				@include media-breakpoint-down(sm) {
					position: relative;
					left: auto;
					top: auto;
				}

				&.right {
					right: 10px;
					left: auto;
				}
			}

			.multiple-circles-2 {
				border-radius: 100%;
				position: relative;
				z-index: 14;
				background: #F2F2F2;
				padding: 5px;
				padding-bottom: 10px;
				top: -6px;
				@include media-breakpoint-down(sm) {
					position: relative;
					left: auto;
					top: auto;
					background: none;
					padding: 0;
				}
			}

			.plan-discount {
				border-radius: 100%;
				margin: 0 auto;
				text-align: center;
				position: relative;
				z-index: 2;
				top: -2px;
				background: #ffffff;
				height: 90px;
				width: 95px;
				@include media-breakpoint-down(sm) {
					background: none;
					margin-bottom: 0;
				}

				h6 {
					padding-top: 20px;
					margin: 0;
					font-size: 2.2em;
					font-weight: 700;
					line-height: .6em;
					letter-spacing: -1px;

					span {
						display: block;
						letter-spacing: 0.001em;
						font-size: .4em;
					}
				}

				img {
					width: 100px;
					margin-top: -5px;
				}
			}
		}

		.plan-benefits-icons {
			text-align: center;
			padding: 20px 0 10px 0;

			span {
				font-size: 50px;
				padding: 0 4%;
			}

		}

		.plan-benefits-title {
			text-align: center;
			margin-bottom: 10px;

			h3 {
				font-size: 1.4em;
				font-weight: 600;
				margin: 0 10px;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
				h3 {
					margin-bottom: 0;
					min-height: 50px;
					font-size: 4vw;
				}
			}
		}

		.plan-price {
			border: 2px #999 dotted;
			border-left: none;
			border-right: none;
			position: relative;
			z-index: 1;
			margin: 10px;
			padding: 15px;
			@include media-breakpoint-down(sm) {
				margin: 0 0 10px 0;
			}
			text-align: center;

			.btn-table-price {
				display: block;
				width: 90%;
				text-align: center;
				margin: 10px auto 0 auto;
				background: lighten($cor_09, 30);
				text-decoration: none;
				color: #ffffff;
				padding: 5px 10px;
				border-radius: 5px;
				@include animado();

				&:hover {
					border-radius: 0px;
					background: $cor_09;
				}
			}

			.plan-from {
				font-size: 1em;
				font-weight: 600;
			}

			.plan-discount {
				font-size: 1.04em;
				font-weight: 600;
				padding: 10px;
				margin: 5px -10px;
				cursor: pointer;
				@include animado;

				a {
					color: #ffffff;
				}
			}

			.plan-price-complete {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: center;
				padding-top: 10px;

				.plan-acronym {
					font-size: 1.8em;
					@include media-breakpoint-down(sm) {
						font-size: 1em;
					}
				}

				.plan-value {
					font-size: 3em;
					@include media_screen_max(1280px) {
						font-size: 3.8em;
					}
					font-weight: 700;
					letter-spacing: -2px;
					line-height: 0.8em;

					span {
						font-size: 0.5em;
					}

					@include media-breakpoint-down(sm) {
						font-size: 2.5em;
					}
				}

				.plan-cents {
					font-size: 1.5em;
					@include media_screen_max(1280px) {
						font-size: 1.5em;
					}
					font-weight: 700;
					letter-spacing: -2px;

					span {
						display: block;
						font-size: 0.3em;
						font-weight: 400;
						line-height: 0.5em;
						letter-spacing: normal;
					}

					@include media-breakpoint-down(sm) {
						font-size: 1.2em;
					}
				}
			}
		}

		.plan-button-info {
			a {
				width: 90%;
				margin: 0 auto;
				padding: 5px;
				font-size: 1.2em;
				font-weight: 700;
				text-align: center;
				color: #666;
				border-radius: 50px;
				text-decoration: none;
				margin-bottom: 20px;
				display: none;
				@include animado();
				@include media-breakpoint-down(sm) {
					padding: 15px 5px;
					font-size: 5vw;
					display: block;
				}
			}
		}

		.plan-info {
			padding: 15px 15px 15px 0;
			text-align: left;
			min-height: 310px;
			border-bottom: 2px #999 dotted;
			margin-bottom: 10px;
			position: relative;
			@include media-breakpoint-down(sm) {
				min-height: inherit;
				display: none;
			}

			ul {
				list-style: none;
				padding-left: 20px;

				li {
					padding-bottom: 5px;
				}
			}

			p {
				text-align: left;
				font-size: .8em;
				padding: 0 10px 10px 10px;
				margin: 0;
				position: absolute;
				bottom: 0;
			}

		}

		.plan-info-mobile {
			display: none;

			p {
				font-size: .8em;
				position: relative;
			}
		}


		.plan-call-to-action {
			a {
				display: block;
				width: 90%;
				margin: 0 auto;
				padding: 10px 5px;
				font-size: 1.8em;
				font-weight: 700;
				text-align: center;
				color: #fff;
				text-decoration: none;
				@include animado();
				@include media-breakpoint-down(sm) {
					padding: 15px 5px;
					font-size: 5vw;
				}

				img {
					max-width: 40px;
				}

				&:hover {
					border-bottom: 4px solid;
					border-radius: 0px;
				}
			}
		}
	}
}

.conar {
	padding-bottom: 20px;
	font-size: 1.1em !important;
	color: $cor_text;
	text-align: center;
}

/* =======================================================================
   TABLE PLANS
   ======================================================================= */
.table-plans {
	padding: 40px 0 0 0;

	p {
		font-size: 1.5em;
		text-align: center;
		padding-bottom: 30px;

		& {
			padding: 0;
		}
	}

	h1 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		padding-bottom: 20px;
		text-align: center;
	}

	h3 {
		text-align: center;

		span {
			color: $cor_01;
			font-weight: 600;

			&.fz-small {
				font-size: 0.5em;
				display: block;
				padding: 5px 0;
			}
		}
	}

	.title-mobile {
		font-size: 1.5em;
		font-weight: 600;
		color: $cor_02;
		text-align: center;
	}

	.table-plans-mobile {
		margin-bottom: 20px;

		@include media-breakpoint-up(md) {
			display: none;
		}

		svg {
			max-width: 4vw;
		}

		table {
			th {
				border: 1px solid #ddd;
				padding: 5px;
				font-size: 3vw;
			}

			td {
				border: 1px solid #ddd;
				padding: 5px;
				text-align: center;
				font-size: 2.9vw;

				&:nth-child(1) {
					text-align: left;
				}
			}

			$cores_planos: (basic, $cor_11, darken($cor_11, 10)), (light, $cor_03, $cor_04), (plus, $cor_05, $cor_06), (total, $cor_07, $cor_08), (premium, $cor_09, $cor_10);

			td:nth-child(2) path {
				fill: $cor_03;
			}

			td:nth-child(3) path {
				fill: $cor_07;
			}

			td:nth-child(4) path {
				fill: $cor_05;
			}

			td:nth-child(5) path {
				fill: $cor_09;
			}

			td:nth-child(6) path {
				fill: $cor_09;
			}

			th:nth-child(2) {
				color: $cor_03;
			}

			th:nth-child(3) {
				color: $cor_07;
			}

			th:nth-child(4) {
				color: $cor_05;
			}

			th:nth-child(5) {
				color: $cor_09;
			}

			th:nth-child(6) {
				color: $cor_09;
			}

		}

		a {
			background: $cor_01;
			display: block;
			width: 98%;
			margin: 10px auto 0 auto;
			padding: 10px 5px;
			font-size: 1.1em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			border-radius: 20px;
			text-decoration: none;
			@include animado();

			@include media-breakpoint-down(sm) {
				padding: 15px 5px;
				border-radius: 50px;
				font-size: 4.2vw;
			}

			&:hover {
				background: $cor_09;
			}
		}

		p {
			font-size: 3vw;
			padding: 10px;
			text-align: left;
		}
	}

	.table-plans-base {
		@include media-breakpoint-down(sm) {
			display: none;
		}
		position: relative;


		div:not(.row):not(.recommends) {
			border-right: 1px #ccc solid;
			border-bottom: 1px #ccc solid;

			&:first-child {
				border-left: 1px #ccc solid;
			}
		}

		div.row:first-child {
			border-top: 1px #ccc solid;
		}

		.row > div:first-child > .cell {
			text-align: left;
		}

		@include media-breakpoint-down(sm) {
			.row {
				padding-bottom: 30px;
				border-top: 1px #ccc solid;

				&:first-child {
					padding-bottom: 0;
					display: none;
				}
			}
		}

		.recommends {
			background: $cor_09;
			color: #ffffff;
			height: 100px;
			width: 50%;
			text-align: center;
			-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 70% 75%, 50% 100%, 30% 75%, 0% 75%);
			clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 70% 75%, 50% 100%, 30% 75%, 0% 75%);
			position: absolute;
			border: solid 3px #fff;
			border-radius: 10px;
			top: -85px;
			left: calc(50% - 25%);
			border-bottom: none;

			&:before {
				content: " ";
				position: absolute;
				z-index: -1;
				top: -1px;
				left: -1px;
				right: -1px;
				bottom: -1px;
				border: 3px solid $cor_07;
				border-radius: 10px;
			}

			span {
				font-size: 2.2em;
				font-weight: 700;
				display: block;
				padding-top: 10px;
				line-height: 1em;

				span {
					padding-top: 0;
					font-size: .4em;
				}
			}
		}

		.bg-featured {
			background: $cor_07;

			& .cell span {
				color: #ffffff !important;
			}
		}

		.cell {
			display: block;
			padding: 10px 0;
			text-align: center;

			strong {
				display: block;
				font-size: .7em;
			}

			@include media-breakpoint-up(sm) {
				strong {
					display: none;
				}
			}

			&.title {
				font-size: 1.8em;
				font-weight: 600;
				color: $cor_02;
				@include media-breakpoint-down(sm) {
					font-size: 1em;
				}
			}

			$cores_planos: (basic, $cor_11, darken($cor_11, 10)), (light, $cor_03, $cor_04), (plus, $cor_05, $cor_06), (total, $cor_07, $cor_08), (premium, $cor_09, $cor_10);
			@each $name_plan, $color_01, $color_02 in $cores_planos {
				&.#{$name_plan} {
					color: #{$color_01};
				}
				&.#{$name_plan}.inverse {
					background: #{$color_01};
					color: #fff;
				}
				&.#{$name_plan}.white-force {
					color: #ddd;
				}
			}

			.icon-h4p-health-icone {
				text-align: center;
				font-size: 1.5em;
			}

			em {
				display: block;
				font-size: .5em;
				font-weight: 400;
				font-style: normal;
				color: $cor_text;
				@include media-breakpoint-down(xs) {
					display: none;
				}
			}
		}
	}

	.table-plans-btns {
		@include media-breakpoint-down(sm) {
			$cores_planos: (basic, $cor_11, darken($cor_11, 10)),
					(light, $cor_03, $cor_04),
					(plus, $cor_05, $cor_06),
					(total, $cor_07, $cor_08),
					(premium, $cor_09, $cor_10);
			@each $name_plan, $color_01, $color_02 in $cores_planos {
				.button-base-#{$name_plan} {
					background: #ffffff;
					-webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
					-moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
					box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
					position: relative;
					border-bottom: 5px solid;
					padding-bottom: 10px;
					margin-bottom: 20px;
					border-left: 1px solid #ccc;
					border-right: 1px solid #ccc;
					border-top: 6px solid #{$color_01};
					border-bottom: 3px solid #{$color_02};

				}
			}
			div[class*="button-base"] {
				a {
					font-size: 6vw !important;
				}

				.plan-value {
					font-size: 15vw;
				}

				.plan-cents {
					font-size: 5vw;
				}

				.plan-from {
					font-size: 4vw;
				}

				.title {
					font-size: 5vw;
				}
			}
		}

		.cell {
			display: block;
			padding: 10px 0;
			text-align: center;

			&.title {
				font-size: 1.5em;
				font-weight: 600;
				color: $cor_02;
				@media (min-width: 1280px) {
					font-size: 1.3em;
				}
				@media (min-width: 1366px) {
					font-size: 1.3em;
				}
			}

			$cores_planos: (basic, $cor_11, darken($cor_11, 10)), (light, $cor_03, $cor_04), (plus, $cor_05, $cor_06), (total, $cor_07, $cor_08), (premium, $cor_09, $cor_10);
			@each $name_plan, $color_01, $color_02 in $cores_planos {

				&.#{$name_plan} {
					color: #{$color_01};

					a {
						background: #{$color_01};
						display: block;
						width: 180px;
						margin: 0 auto;
						padding: 15px 5px;
						font-size: 1.1em;
						font-weight: 700;
						text-align: center;
						color: #fff;
						border-radius: 30px;
						text-decoration: none;
						@include animado();

						@include media-breakpoint-down(sm) {
							padding: 15px 5px;
							border-radius: 50px;
							font-size: 1.8em;
						}

						img {
							width: 25px;
						}

						&:hover {
							border-radius: 0;
							border-color: #{$color_02};
						}
					}
				}
				&.#{$name_plan}.inverse {
					background: #{$color_01};
					color: #fff;
				}
				&.#{$name_plan} + .plan-price {
					.plan-discount {
						font-size: 0.75em;
						text-align: center;
						font-weight: 600;
						padding: 10px;
						margin: 5px -10px;
						cursor: pointer;
						background: #{$color_01};
						@include animado;

						a {
							color: #ffffff;
						}
					}
				}
				&.#{$name_plan} + .plan-price .plan-price-complete {
					color: #{$color_01} !important;

				}
			}
		}

		.plan-from {
			font-size: 0.6em;
			text-align: center;
			font-weight: 600;
		}

		.plan-price-complete {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;

			.plan-acronym {
				font-size: .8em;
			}

			.plan-value {
				font-size: 2em;
				@media (min-width: 1280px) {
					font-size: 2em;
				}
				@media (min-width: 1366px) {
					font-size: 2em;
				}
				font-weight: 700;
				line-height: 0.8em;

				span {
					font-size: 0.5em;
				}
			}

			.plan-cents {
				font-size: 1em;
				@media (min-width: 1280px) {
					font-size: 1em;
				}
				@media (min-width: 1366px) {
					font-size: 1em;
				}
				font-weight: 700;

				span {
					display: block;
					font-size: 0.3em;
					font-weight: 400;
					line-height: 0.5em;
					letter-spacing: normal;
				}
			}
		}
	}

	.action-full {
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
		@include media-breakpoint-down(sm) {
			display: none;
		}

		a {
			background: $cor_01;
			display: inline-block;
			margin: 10px auto 0 auto;
			padding: 10px 20px;
			font-size: 1.6em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			border-radius: 20px;
			text-decoration: none;
			@include animado();

			@include media-breakpoint-down(sm) {
				display: none;
			}

			&:hover {
				background: $cor_09;
			}
		}
	}
}

/* =======================================================================
   ACCREDITED NETWORK
   ======================================================================= */
.types-care {
	padding: 40px 0;
	//background: #caedf9;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		text-align: center;

		span {
			color: $cor_text;
			display: block;
		}
	}

	a {
		background: $cor_15;
		font-size: 1.6em;
		font-weight: 700;
		color: #fff;
		padding: 15px 30px;
		display: inline-block;
		margin: 20px;
		border-radius: 80px;
		text-align: center;
		@include media-breakpoint-up(sm) {
			max-width: 270px;
		}
		@include animado();

		&:hover {
			background: $cor_11 !important;
			text-decoration: none;
		}

		&.telephone {
			background: $cor_01;
		}

		&.whatsapp {
			background: $cor_03;

			&:before {
				content: '';
			}
		}

		&.call-me {
			background: $cor_09;
		}

		span {
			font-size: 1.5em;
			vertical-align: text-bottom;
		}
	}
}


/* =======================================================================
   ACCREDITED NETWORK
   ======================================================================= */
.accredited-network {
	padding: 10px 0 0 0;
	background: url("../images/bg-rede-credenciada.jpg") center top no-repeat;
	background-size: cover;

	@include media-breakpoint-down(sm) {
		padding: 40px 0;
	}

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		margin-top: 40px;

		span {
			color: $cor_text;
			display: block;
		}

		&:before {
			content: '';
			position: absolute;
			top: 30px;
			left: -50px;
			border-top: 6px solid $cor_13;
			height: 10px;
			width: 40px;
		}
	}

	ul {
		list-style: none;

		li {
			color: $cor_text;
			font-size: 1.2em;
			line-height: 1.3em;
			margin-bottom: 10px;

			&:before {
				content: "•";
				vertical-align: text-top;
				//content: "\2022";
				color: $cor_01;
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
		}
	}

	a {
		background: $cor_01;
		font-size: 1.6em;
		font-weight: 700;
		color: #fff;
		padding: 20px;
		display: block;
		margin: 50px auto 50px auto;
		border-radius: 80px;
		text-align: center;
		@include media-breakpoint-up(sm) {
			max-width: 270px;
		}
		@include animado();

		&:hover {
			background: $cor_11;
			text-decoration: none;
		}
	}
}

/* =======================================================================
   EXCLUSIVE SERVICES
   ======================================================================= */
.exclusive-services {
	padding: 30px 0;
	border-top: lighten($cor_02, 10) solid 3px;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		margin-bottom: 30px;

		span {
			color: $cor_text;
			display: block;
		}

		&:before {
			content: '';
			position: absolute;
			top: 30px;
			left: -50px;
			border-top: 6px solid $cor_13;
			height: 10px;
			width: 40px;
		}
	}

	.row .col-xl:nth-child(even) {
		.icons-featured {
			span {
				color: $cor_13;
			}
		}
	}

	.row .col-xl:nth-child(odd) {
		.icons-featured {
			span {
				color: $cor_15;
			}
		}
	}

	.icons-featured {
		text-align: center;
		padding-top: 30px;

		span {
			font-size: 5em;
		}

		@include media-breakpoint-down(sm) {
			span {
				font-size: 4em;
			}
		}

		h5 {
			font-size: 1.3em;
			font-weight: 700;
			color: $cor_14;
			margin: 15px 0;
		}

		p {
			color: $cor_text;
			font-size: 1.2em;
			line-height: 1.3em;
		}
	}

	a {
		background: $cor_01;
		font-size: 1.6em;
		font-weight: 700;
		color: #fff;
		padding: 20px;
		display: block;
		margin: 20px auto 0 auto;
		border-radius: 80px;
		text-align: center;
		@include media-breakpoint-up(sm) {
			max-width: 270px;
		}
		@include animado();

		&:hover {
			background: $cor_11;
			text-decoration: none;
		}
	}
}

/* =======================================================================
   TECHNOLOGY PRACTICALITY
   ======================================================================= */
.technology-practicality {
	padding: 20px 0;
	//background: url("../images/bg-tecnologia-praticidade.jpg") center top no-repeat;
	background-size: cover;
	border-top: lighten($cor_02, 10) solid 3px;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		margin-top: 80px;

		span {
			color: $cor_text;
			display: block;
		}

		&:before {
			content: '';
			position: absolute;
			top: 30px;
			left: -50px;
			border-top: 6px solid $cor_13;
			height: 10px;
			width: 40px;
		}
	}

	ul {
		list-style: none;

		li {
			color: $cor_text;
			font-size: 1.2em;
			line-height: 1.3em;
			margin-bottom: 10px;

			&:before {
				content: "•";
				vertical-align: text-top;
				//content: "\2022";
				color: $cor_01;
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
		}
	}

	h3 {
		font-weight: 700;
		font-size: 1.6em;
		margin-bottom: 20px;
		color: $cor_01;
	}

}

/* =======================================================================
   FORM BOTTOM
   ======================================================================= */
.form-middle {
	padding: 50px 0;
	background: $cor_11;

	form {
		position: relative;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		h3 {
			font-weight: 700;
			color: $cor_14;
			text-align: center;
			margin-bottom: 20px;
			font-size: 3.2em;
			line-height: 1em;
			@include media-breakpoint-down(sm) {
				font-size: 2.1em;
			}
		}

		input {
			margin: 10px 0;
			height: 50px;
			width: 100%;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			display: block;
			border-radius: 30px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 6px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_01 solid 1px;
			}
		}

		button {
			background: $cor_14;
			font-size: 1.6em;
			font-weight: 700;
			color: #fff;
			height: 50px;
			display: block;
			margin: 10px 0;
			width: 100%;
			@include media-breakpoint-up(sm) {
				max-width: 200px;
			}
			border-radius: 30px;
			@include animado();

			&:hover {
				background: $cor_02;
				text-decoration: none;
				padding: 10px 40px;
			}
		}
	}

	.page-diferenciais & {
		padding: 50px 0;
		background: $cor_15;

		form {
			h3 {
				color: #fff;
			}
		}
	}
}

/* =======================================================================
   PORTO SERVICES
   ======================================================================= */
.porto-services {
	padding: 40px 0;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_13;
		font-size: 2.5em;
		text-align: center;
		margin-bottom: 20px;
		@include media-breakpoint-down(sm) {
			font-size: 1.8em;
		}
	}

	p {
		text-align: center;
		font-size: 1.8em;
		@include media-breakpoint-down(sm) {
			font-size: 1.4em;
		}
		padding: 0;
		margin: 0;
	}
}

/* =======================================================================
   INSTITUTIONAL TEXT
   ======================================================================= */
.institutional-text {
	padding: 40px 0;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_01;
		font-size: 2.5em;
		text-align: center;
		margin-bottom: 20px;
		@include media-breakpoint-down(sm) {
			font-size: 1.8em;
		}
	}

	p {
		text-align: center;
		font-size: 1.8em;
		@include media-breakpoint-down(sm) {
			font-size: 1.4em;
		}
		padding: 0;
		margin: 0;
	}
}

/* =======================================================================
   FORM BOTTOM
   ======================================================================= */
.form-bottom {
	padding: 50px 0;
	background: $cor_16;

	form {
		position: relative;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		h3 {
			font-weight: 700;
			color: $cor_14;
			text-align: center;
			margin-bottom: 20px;
			font-size: 3.2em;
			line-height: 1em;
			@include media-breakpoint-down(sm) {
				font-size: 2.1em;
			}
		}

		input {
			margin: 10px 0;
			height: 50px;
			width: 100%;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			display: block;
			border-radius: 30px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 6px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_01 solid 1px;
			}
		}

		button {
			background: $cor_14;
			font-size: 1.6em;
			font-weight: 700;
			color: #fff;
			height: 50px;
			display: block;
			margin: 10px 0;
			width: 100%;
			@include media-breakpoint-up(sm) {
				max-width: 200px;
			}
			border-radius: 30px;
			@include animado();

			&:hover {
				background: $cor_11;
				text-decoration: none;
				padding: 10px 40px;
			}
		}
	}

	.page-diferenciais & {
		padding: 50px 0;
		background: $cor_15;

		form {
			h3 {
				color: #fff;
			}
		}
	}
}


/* =======================================================================
   CONTACT
   ======================================================================= */
.contact {
	padding: 40px 0;
	border-top: 3px solid $cor_13;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		padding-bottom: 20px;

		span {
			color: $cor_text;
			display: block;
		}

		&:before {
			content: '';
			position: absolute;
			top: 30px;
			left: -50px;
			border-top: 6px solid $cor_13;
			height: 10px;
			width: 40px;
		}
	}

	p {
		font-size: 1.3em;
		text-align: center;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	div.title-contact {
		padding-top: 60px;

		h3 {
			font-weight: 700;
			color: $cor_10;
			text-align: center;
			margin-bottom: 20px;
			font-size: 3.2em;
		}

		p {
			text-align: center;
			font-size: 1.5em;
		}
	}

	form.form-contact {
		position: relative;
		padding-bottom: 60px;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		input {
			width: 100%;
			margin: 10px 0;
			height: 50px;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			border-radius: 30px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_02 solid 1px;
			}
		}

		textarea {
			width: 100%;
			margin: 10px 0;
			height: 300px;
			border: #ccc solid 1px;
			padding-left: 35px;
			padding-top: 20px;
			position: relative;
			border-radius: 30px;
			outline: none;


			&:focus {
				border: $cor_02 solid 1px;
			}
		}

		button {
			background: $cor_14;
			font-size: 2em;
			font-weight: 700;
			color: #fff;
			padding: 10px 0;
			width: 200px;
			display: block;
			margin: 15px auto 0 auto;
			border-radius: 30px;
			float: right;
			@include animado();

			&:hover {
				background: $cor_11;
				text-decoration: none;
				padding: 10px 40px;
			}
		}
	}
}

/* =======================================================================
   CONTACT MODAL
   ======================================================================= */
// FANCYBOX CUSTOM
.fancybox-slide > * {
	padding: 0 !important;
}

.fancybox-close-small {
	color: #ffffff !important;
	padding: 0 !important;
	width: 30px !important;
	height: 30px !important;
}

.fancybox-close-small:after {
	background-color: #ffffff;

}

.page-form-modal, .page-form-modal-email {
	padding-top: 0;
}

:root {
	/* Set neon color */
	--neon-text-color: #f40;
	--neon-border-color: #08f;
}

.fancybox-bg {
	background: #000000;
}

.fancybox-content {
	background: transparent;
}

#modal, #modal-contratar {
	display: none;
	width: 350px;
	border: darken($cor_18, 10) solid 3px;
	border-radius: 10px;
	padding: 10px !important;
	margin: 20px 0;
	background: #000;
	color: #ffffff;

	h2, h5 {
		text-align: center;
	}

	form {
		position: relative;

		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #aaa;
		}

		h4 {
			color: #fff;
			font-weight: 600;
		}

		input[type="text"] {
			width: 90%;
			margin: 10px auto;
			height: 50px;
			border: #ccc solid 2px;
			padding-left: 35px;
			position: relative;
			display: block;
			border-radius: 5px;

			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_18 solid 2px;
			}
		}

		button {
			background: $cor_18;
			font-weight: 700;
			color: #fff;
			padding: 3px 40px;
			display: block;
			margin: 10px auto 0 auto;
			border-radius: 30px;
			font-size: 1.8em;
			@include animado();

			&:hover {
				background: darken($cor_18, 10);
				text-decoration: none;
				color: #ffffff;
			}

			@include media-breakpoint-down(sm) {
				font-size: 7vw;
				margin-top: 20px;
			}
		}
	}
}


.contact-modal {
	background: $cor_09 url('../images/img-rejeicao.png') center bottom no-repeat;
	background-size: contain;
	padding-bottom: 130px;

	div#form-rejeicao {
		margin: 0;
		padding: 35px 10px 10px 10px;
		width: 100%;

		.fechar-modal {
			background: #fff;
			color: #4ec1ea;
			font-size: 30px;
			font-weight: 700;
			padding: 5px 20px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 9999;
		}

		.logo-rejeicao {
			background: #ffffff;
			margin: -15px;
			padding: 15px;
			text-align: center;

		}

		h3 {
			font-size: 2em;
			line-height: 0.9em;
			font-weight: 700;
			color: #ffffff;
			text-align: center;
			margin: 0;
			padding: 5px;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

			strong {
				font-size: 0.8em;
				font-weight: 700;
			}
		}

		form {
			padding: 5px 20px 10px 20px;
			position: relative;
			z-index: 10;
			border-radius: 4px;

			h4 {
				color: #fff;
				font-size: 1.6em;
				text-align: center;
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

				a {
					color: #fff;
					display: block;
					font-size: 1.5em;
					padding: 5px 10px;
					text-decoration: none;
				}

			}

			input {
				width: 90%;
				display: block;
				margin: 15px auto 15px auto;
				height: 50px;
				border: #ccc solid 1px;
				padding-left: 35px;
				position: relative;
				border-radius: 30px;

				&:first-child {
					margin-top: 5px;
				}

				@each $name in 'nome' 'telefone' 'email' 'cep' {
					&[name="#{$name}"] {
						background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
						background-size: 26px;
					}
				}

				&:focus {
					border: $cor_02 solid 1px;
				}
			}

			.inputGroup {
				background-color: #fff;
				display: block;
				margin: 10px 0;
				position: relative;

				label {
					padding: 12px 30px 12px 50px;
					width: 100%;
					display: block;
					text-align: left;
					color: #3C454C;
					cursor: pointer;
					position: relative;
					z-index: 2;
					transition: color 200ms ease-in;
					overflow: hidden;
					font-weight: 600;

					&:before {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%) scale3d(1, 1, 1);
						transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
						opacity: 0;
						z-index: -1;
					}

					&:after {
						width: 32px;
						height: 32px;
						content: '';
						border: 2px solid #D1D7DC;
						background-color: #fff;
						background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
						background-repeat: no-repeat;
						background-position: 2px 3px;
						border-radius: 50%;
						z-index: 2;
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						cursor: pointer;
						transition: all 200ms ease-in;
					}
				}

				input:checked ~ label {
					&:before {
						transform: translate(-50%, -50%) scale3d(56, 56, 1);
						opacity: 1;
					}

					&:after {
						background-color: $cor_09;
						border-color: $cor_09;
					}
				}

				input {
					width: 32px;
					height: 32px;
					order: 1;
					z-index: 2;
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;
					visibility: hidden;
				}
			}

			button {
				background: $cor_03;
				font-size: 1.7em;
				font-weight: 700;
				color: #fff;
				padding: 10px 0;
				width: 90%;
				display: block;
				margin: 15px auto 15px auto;
				border-radius: 30px;
				@include animado();

				&:hover {
					color: $cor_10;
					background: $cor_11;
					text-decoration: none;
					padding: 10px 40px;
				}
			}
		}
	}
}

/*.contact-modal {
	//background: $cor_09 url('../images/img-rejeicao.png') center bottom no-repeat;
	background-size: contain;
	padding: 50px;
	background: transparent;

	div#form-rejeicao {
		animation: flicker 1.5s infinite alternate;
		background-color: var(--neon-border-color);
		background: #000000;
		margin: 0;
		padding: 35px 10px 10px 10px;
		width: 100%;

		.fechar-modal {
			background: #fff;
			color: #4ec1ea;
			font-size: 30px;
			font-weight: 700;
			padding: 5px 20px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 9999;
		}

		.logo-rejeicao {
			background: #ffffff;
			margin: -15px;
			padding: 15px;
			text-align: center;

		}

		@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);

		h3 {
			font-family: 'Exo 2', sans-serif;
			font-size: 3em;
			@include media-breakpoint-down(sm) {
				font-size: 8vw;
				line-height: normal;
			}

			font-weight: 600;
			color: #ffffff;
			text-align: center;
			margin: 0;
			padding: 10px;
			-webkit-animation: neon1 1.5s ease-in-out infinite alternate;
			-moz-animation: neon1 1.5s ease-in-out infinite alternate;
			animation: neon1 1.5s ease-in-out infinite alternate;

			strong {
				font-size: 0.8em;
				font-weight: 700;
			}
		}

		form {
			padding: 5px 20px 10px 20px;
			position: relative;
			z-index: 10;
			border-radius: 4px;

			h4 {
				color: #fff;
				font-size: 1.6em;
				text-align: center;
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

				a {
					color: #fff;
					display: block;
					font-size: 1.5em;
					padding: 5px 10px;
					text-decoration: none;
				}

			}

			input {
				width: 90%;
				display: block;
				margin: 15px auto 15px auto;
				height: 50px;
				border: #ccc solid 1px;
				padding-left: 35px;
				position: relative;
				border-radius: 30px;

				&:first-child {
					margin-top: 5px;
				}


				&:focus {
					border: $cor_02 solid 1px;
				}
			}

			button {
				background: $cor_03;
				font-size: 1.7em;
				font-weight: 700;
				color: #fff;
				padding: 10px 0;
				width: 90%;
				display: block;
				margin: 15px auto 15px auto;
				border-radius: 30px;
				@include animado();

				&:hover {
					color: $cor_10;
					background: $cor_11;
					text-decoration: none;
					padding: 10px 40px;
				}
			}
		}
	}
}*/


@keyframes flicker {

	0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {

		//text-shadow: -0.2rem -0.2rem 1rem #fff,
		//0.2rem 0.2rem 1rem #fff,
		//0 0 2rem var(--neon-text-color),
		//0 0 4rem var(--neon-text-color),
		//0 0 6rem var(--neon-text-color),
		//0 0 8rem var(--neon-text-color),
		//0 0 10rem var(--neon-text-color);

		box-shadow: 0 0 .5rem #fff,
		inset 0 0 .5rem #fff,
		0 0 1.5rem var(--neon-border-color),
		inset 0 0 1.5rem var(--neon-border-color),
		0 0 3rem var(--neon-border-color),
		inset 0 0 3rem var(--neon-border-color);
	}

	20%, 24%, 55% {
		text-shadow: none;
		box-shadow: none;
	}
}


/*glow*/

@keyframes neon1 {
	from {
		text-shadow: 0 0 0rem #fff, 0 0 0.1rem #fff, 0 0 0.6rem #fff, 0 0 0.9rem #FF1177, 0 0 1.4rem #FF1177, 0 0 1.9rem #FF1177, 0 0 2.4rem #FF1177, 0 0 2.9rem #FF1177;
	}
	to {
		text-shadow: 0 0 0rem #fff, 0 0 0.5rem #fff, 0 0 1rem #fff, 0 0 1.5rem #FF1177, 0 0 2rem #FF1177, 0 0 2.5rem #FF1177, 0 0 3rem #FF1177, 0 0 3.5rem #FF1177;
	}
}

@keyframes neon2 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228DFF, 0 0 35px #228DFF, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF;
	}
}

@keyframes neon3 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FFDD1B, 0 0 35px #FFDD1B, 0 0 40px #FFDD1B, 0 0 50px #FFDD1B, 0 0 75px #FFDD1B;
	}
}

@keyframes neon4 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #B6FF00, 0 0 70px #B6FF00, 0 0 80px #B6FF00, 0 0 100px #B6FF00, 0 0 150px #B6FF00;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #B6FF00, 0 0 35px #B6FF00, 0 0 40px #B6FF00, 0 0 50px #B6FF00, 0 0 75px #B6FF00;
	}
}

@keyframes neon5 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF9900, 0 0 70px #FF9900, 0 0 80px #FF9900, 0 0 100px #FF9900, 0 0 150px #FF9900;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF9900, 0 0 35px #FF9900, 0 0 40px #FF9900, 0 0 50px #FF9900, 0 0 75px #FF9900;
	}
}

@keyframes neon6 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de, 0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
	}
}


.accredited-network-modal {
	background: $cor_13;
	height: 520px;
	width: 450px;
	@include media-breakpoint-down(sm) {
		width: 350px;
	}

	& [class^="col-"] {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.title {
		text-align: center;
		padding: 15px 0 0 0;

		h3 {
			font-size: 2em;
			font-weight: 700;
			color: #fff;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
			background: $cor_09;
			margin-top: -15px;
			padding: 15px 0;

			a {
				color: #fff;

				span {
					font-size: .6em;
				}
			}
		}

		h4 {
			color: #fff;
			font-size: 1.6em;
			font-weight: 700;
			padding: 10px;
			text-align: center;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

			a {
				color: #fff;
				display: block;
				font-size: 1.5em;
				padding: 5px 10px;
				text-decoration: none;
				outline: none;
			}

		}
	}

	form.form-contact {
		position: relative;
		padding: 10px;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		input {
			width: 100%;
			margin: 10px 0;
			height: 50px;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			border-radius: 30px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_02 solid 1px;
			}
		}

		button {
			margin: 15px auto;
			width: 100%;
			background: $cor_11;
			border-bottom: 4px solid darken($cor_11, 10);
			display: inline-block;
			padding: 10px 50px;
			font-size: 1.5em;
			font-weight: 700;
			text-align: center;
			color: $cor_10;
			border-radius: 30px;
			text-decoration: none;
			@include animado();

			&:hover {
				border-radius: 0px;
			}
		}
	}
}

/* =======================================================================
   TABLE PRICE
   ======================================================================= */
.table-price {
	width: 900px;
	@include media-breakpoint-down(md) {
		width: 700px;
	}
	@include media-breakpoint-down(sm) {
		width: 350px;
	}
	text-align: center;

	& [class^="col-"] {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.table {
		font-size: 0.9em;
		vertical-align: middle;
		margin-bottom: 0 !important;

		span {
			font-size: .7em;
		}

		th {
			font-size: 2em;
			color: #ffffff;
		}

		.info-tool-tip {
			font-size: 1.2em;
			cursor: pointer;
		}

		.price {
			font-size: 1.5em;
			font-weight: 600;
		}

		$cores_planos: (basic, $cor_11, darken($cor_11, 10)),
				(light, $cor_03, $cor_04),
				(plus, $cor_05, $cor_06),
				(total, $cor_07, $cor_08),
				(premium, $cor_09, $cor_10);
		@each $name_plan, $color_01, $color_02 in $cores_planos {
			&.#{$name_plan} {
				th {
					background: $color_01;
				}

				.info-tool-tip {
					color: $color_01;
				}

				.price {
					color: $color_01;
				}
			}
		}
	}

}

/* =======================================================================
   FOOTER
   ======================================================================= */
footer {
	padding: 30px 0;
	//background: #eeedeb;
	border-top: 5px solid $cor_13;

	.icon-logo-footer {
		height: 40px;
	}

	.bar-bottom {
		border-top: 5px solid #ffffff;
	}

	.logo-bottom {
	}

	.social-media {
		text-align: center;
		margin-bottom: 20px;

		a {
			line-height: 50px;
			font-size: 1.3em;
			font-weight: 600;
			color: #777;
			text-decoration: none;

			span {
				border-radius: 100%;
				background: #b5b5b5;
				background: -moz-linear-gradient(top, #b5b5b5 0%, #ffffff 99%);
				background: -webkit-linear-gradient(top, #b5b5b5 0%, #ffffff 99%);
				background: linear-gradient(to bottom, #b5b5b5 0%, #ffffff 99%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b5b5b5', endColorstr='#ffffff', GradientType=0);
				display: inline-block;
				font-size: 1.2em;
				line-height: 50px;
				height: 50px;
				width: 50px;
				-webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
				box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
			}

			sub {
				bottom: 0;
			}
		}
	}

	.signature {
		text-align: center;
		font-size: .8em;
		padding: 30px 10px;
	}

	.whatsapp {
		background: #ffffff;
		padding: 5px 0;
		text-align: center;
		font-size: 1.8em;
		font-weight: 700;

		span {
			color: $cor_09;
			vertical-align: middle;
		}

		a {
			color: $cor_09;

			span {
				font-size: 0.5em;
			}
		}

		margin: 10px -40px;
	}
}

/* =======================================================================
   WHATS FLOATING
   ======================================================================= */
.whats-floating {
	.page-agradecimento & {
		display: none;
	}

	position: fixed;
	right: 15px;
	bottom: 0;
	width: 300px;
	height: 180px;
	background: url("../images/bg-whats-chat.png") no-repeat 0 0;
	z-index: 99999;
	@include media-breakpoint-down(sm) {
		display: none !important;
	}

	form {
		padding-top: 95px;

		input {
			width: 100%;
			margin: 5px 0 0 0;
			height: 20px;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 16px;
				}
			}
		}

		button {
			background: $cor_04;
			font-size: .8em;
			font-weight: 700;
			color: #fff;
			padding: 5px 30px;
			display: block;
			margin: 5px auto 0 auto;
			@include animado();

			&:hover {
				background: $cor_03;
			}
		}
	}
}

#ouibounce-modal {
	.page-agradecimento & {
		display: none !important;
	}

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: none;

	@include media-breakpoint-down(sm) {
		display: none !important;
	}

	.underlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.7);
		cursor: pointer;
		animation: fadein 0.5s;
		z-index: 9999;
	}

	.modal {
		width: 510px;
		height: 560px;
		background: #fff;
		z-index: 9999;
		display: block;
		position: absolute;
		margin: auto;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 4px;
		animation: popin 0.3s;
	}

	.modal-title {
		font-size: 18px;
		background-color: $cor_10;
		color: #fff;
		padding: 10px;
		margin: 0;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 4px 4px 0 0;
		text-align: center;
	}

	h3 {
		margin-top: 20px;
		font-size: 1.5em;
	}

	div#form-rejeicao {
		display: block;
		position: relative;
		overflow: hidden;
		margin: 0 auto;
		padding: 15px 0;
		color: #333;


		.fechar-rejeicao {
			background: #fff;
			color: #01397c;
			cursor: pointer;
			font-size: 20px;
			font-weight: 900;
			padding: 2px 8px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 9999;
		}


		form {
			padding: 5px 20px 10px 20px;
			position: relative;
			z-index: 10;
			border-radius: 4px;

			h4 {
				font-size: 1.6em;
				text-align: center;

				a {
					color: #fff;
					display: block;
					font-size: 1.5em;
					padding: 5px 10px;
					text-decoration: none;
				}

			}

			input {
				width: 70%;
				display: block;
				margin: 15px auto 15px auto;
				height: 50px;
				border: #ccc solid 2px;
				padding-left: 35px;
				position: relative;
				border-radius: 5px;

				&:first-child {
					margin-top: 5px;
				}

				@each $name in 'nome' 'telefone' 'email' 'cep' {
					&[name="#{$name}"] {
						background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
						background-size: 26px;
					}
				}

				&:focus {
					border: $cor_02 solid 2px;
				}
			}

			.inputGroup {
				background-color: #fff;
				display: block;
				margin: 10px 0;
				position: relative;

				label {
					padding: 12px 30px 12px 50px;
					width: 100%;
					display: block;
					text-align: left;
					color: #3C454C;
					cursor: pointer;
					position: relative;
					z-index: 2;
					transition: color 200ms ease-in;
					overflow: hidden;
					font-weight: 600;

					&:before {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%) scale3d(1, 1, 1);
						transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
						opacity: 0;
						z-index: -1;
					}

					&:after {
						width: 32px;
						height: 32px;
						content: '';
						border: 2px solid #D1D7DC;
						background-color: #fff;
						background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
						background-repeat: no-repeat;
						background-position: 2px 3px;
						border-radius: 50%;
						z-index: 2;
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						cursor: pointer;
						transition: all 200ms ease-in;
					}
				}

				input:checked ~ label {
					&:before {
						transform: translate(-50%, -50%) scale3d(56, 56, 1);
						opacity: 1;
					}

					&:after {
						background-color: #54E0C7;
						border-color: #54E0C7;
					}
				}

				input {
					width: 32px;
					height: 32px;
					order: 1;
					z-index: 2;
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;
					visibility: hidden;
				}
			}

			button {
				background: $cor_18;
				font-size: 1.7em;
				font-weight: 700;
				color: #fff;
				padding: 10px 0;
				width: 70%;
				display: block;
				margin: 15px auto 15px auto;
				@include animado();

				&:hover {
					color: $cor_10;
					background: $cor_11;
					text-decoration: none;
					padding: 10px 40px;
				}
			}
		}
	}
}

@-webkit-keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes popin {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0);
		opacity: 0;
	}
	85% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.05);
		opacity: 1;
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
		opacity: 1;
	}
}

@-ms-keyframes popin {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0);
		opacity: 0;
	}
	85% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.05);
		opacity: 1;
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes popin {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0);
		opacity: 0;
	}
	85% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.05);
		opacity: 1;
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
		opacity: 1;
	}
}

//.teste {
//	@include make-container-max-widths();
//	@include make-container();
//	background: #000;
//	color: #f-ff;
//	.teste-2 {
//		@include make-row();
//		.teste-3 {
//			@include make-col-ready();
//			@include make-col(2);
//		}
//	}
//}


.banner-footer {
	background: #ffc10e;
	border-top: 4px solid $cor_01;

	a {
		text-align: center;

		img {
			display: block;
			margin: 0 auto;
		}
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

/* =======================================================================
   FOOTER FLOATING
   ======================================================================= */
@include media-breakpoint-down(sm) {
	.zopim {
		display: none !important;
	}
}

/* =======================================================================
   SEO CONTENT
   ======================================================================= */
.seo-content {
	padding: 40px 0;

	h1 {
		text-align: center;
		font-weight: 700;
		color: $cor_14;
		margin-bottom: 30px;
	}

	p {
		font-size: 1.2em;
		text-align: center;
	}
}

/* =======================================================================
   PAGE HEALTH FOR PET
   ======================================================================= */
.page-healthforpet {
	background: url("../images/bg-hot-page.jpg") repeat;
	padding-top: 45px;

	.container {
		background: #ffffff;
	}

	header {
		border-top: 3px solid $cor_01;
		box-shadow: none;

		h2 {
			color: $cor_14;
			font-weight: 700;
		}

		@include media-breakpoint-down(sm) {
			h2 {
				color: $cor_14;
				font-weight: 700;
				font-size: 1.2em;
				text-align: center;
			}
		}

		.whats-top {
			margin-right: 50px;

			img {
				width: 80px;
			}

			p {
				font-size: 1.3em;
				text-align: center;
				margin-bottom: 0;

				a {
					color: $cor_text;

					.tel {
						font-weight: 600;
						font-size: 1.4em;

						span {
							font-size: 0.7em;
						}
					}
				}
			}

			@include media-breakpoint-down(sm) {
				margin-right: 0;
				img {
					width: 40px;
				}

				p {
					font-size: 1em;
					text-align: center;
					margin-bottom: 0;

					a {
						color: $cor_text;

						.tel {
							font-weight: 600;
							font-size: 1em;

							span {
								font-size: 0.7em;
							}
						}
					}
				}
			}
		}
	}

	.action-top {
		margin-top: 50px;
		@include media-breakpoint-down(sm) {
			margin-top: 0;
		}

		.container {

			text-align: center;
			padding: 10px 0;

			h1 {
				color: #ffffff;
				font-weight: 700;
				background: $cor_13;
				padding: 10px 0;
				@include media-breakpoint-down(sm) {
					margin-top: 0;
					font-size: 2em;
					span {
						display: block;
					}
				}
			}

			p {
				color: $cor_09;
				font-weight: 600;
				font-size: 1.3em;
				@include media-breakpoint-down(sm) {
					padding-top: 20px;
					font-size: 1.1em;
					line-height: 1.8em;
				}
			}

			.promotion {
				background: $cor_01;
				padding: 5px 0;

				h2 {
					font-size: 3em;
					color: #ffffff;
					font-weight: 700;
				}

				p {
					color: #ffffff;
					font-size: 2em;
				}

				@include media-breakpoint-down(sm) {
					span {
						display: block;
						visibility: hidden;
						height: 4px;
					}
					h2 {
						font-size: 1.8em;
					}
					p {
						font-size: 1.4em;
						padding-top: 0;
						line-height: 1.3em;
					}
				}
			}
		}
	}

	.content-hot-page {
		.container {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.left-content {
			h2 {
				font-weight: 700;
				margin-bottom: 20px;

			}

			ul {
				padding-left: 10px;

				&:nth-child(2) {
					padding-bottom: 20px;
				}

				li {
					font-weight: 600;
					list-style: none;
					padding-left: 25px;
					font-size: 1.3em;
					background-image: url("../images/icon-check.svg");
					background-repeat: no-repeat;
					background-size: 20px;
					margin-bottom: 10px;
				}
			}
		}

		.center-content {
			text-align: center;
			@include media-breakpoint-down(sm) {
				text-align: left;
				.icon-health4pet {
					display: none;
				}
				.banner-hot {
					width: 200px;
				}
			}

			.plan-pet-icon {
				text-align: center;
				position: relative;


				.multiple-circles {
					background: $cor_01;
					border-radius: 100%;
					position: absolute;
					z-index: 14;
					right: 0;
					top: 0;
					padding: 2px;
				}

				.multiple-circles-2 {
					border-radius: 100%;
					position: relative;
					z-index: 14;
					background: #F2F2F2;
					padding: 5px;
					padding-bottom: 10px;
					top: -6px;
				}

				.plan-discount {
					border-radius: 100%;
					margin: 0 auto;
					text-align: center;
					position: relative;
					z-index: 2;
					top: -2px;
					background: #ffffff;
					height: 200px;
					width: 210px;
					padding-top: 10px;

					h6 {
						color: $cor_10;
						padding-top: 30px;
						margin: 0;
						font-size: 5.5em;
						font-weight: 700;
						line-height: .6em;
						letter-spacing: -3px;

						span {
							display: block;
							letter-spacing: 0.001em;
							font-size: .3em;
							line-height: 1.2em;
							padding: 5px 0;

						}
					}

					img {
						width: 120px;
						margin-top: -10px;
					}
				}
			}

			@include media-breakpoint-down(sm) {
				.plan-pet-icon {
					.plan-discount {
						height: 110px;
						width: 120px;
						padding-top: 0;

						h6 {
							color: $cor_07;
							padding-top: 10px;
							margin: 0;
							font-size: 3.0em;
							font-weight: 700;
							line-height: .6em;
							letter-spacing: -3px;

							span {
								display: block;
								letter-spacing: 0.001em;
								font-size: .3em;
								line-height: 1.2em;
								padding: 5px 0;
							}
						}

						img {
							width: 120px;
							margin-top: -10px;
						}
					}
				}
			}

		}

		.right-content {
			form.form-top {
				background: $cor_13;
				border-radius: 20px;
				padding: 30px 20px;
				position: relative;
				margin-top: 65px;
				@include media-breakpoint-down(sm) {
					margin-bottom: 30px;
					margin-top: 0;
				}
				@include placeholder {
					font-weight: 500;
					font-size: 18px;
					color: #cccccc;
				}

				h2 {
					font-weight: 700;
					color: #fff;
					text-align: center;
					margin-bottom: 20px;
					font-size: 1.8em;
					line-height: 1em;
				}

				h3 {
					font-weight: 700;
					color: #fff;
					text-align: center;
					margin-bottom: 20px;
					font-size: 1.8em;
					line-height: 1em;
				}

				h4 {
					font-weight: 700;
					color: $cor_10;
					text-align: center;
					margin-bottom: 20px;
					font-size: 2em;
				}

				.spotlight {
					background: $cor_10;
					margin: -30px -20px;
					margin-bottom: 10px;
					border-radius: 20px 20px 0 0;
					text-align: center;

					h4 {
						color: $cor_11;
						margin-bottom: 0;
						line-height: 1em;
						padding-top: 5px;
						padding-bottom: 5px;
					}

					p {
						color: #ffffff;
						padding-bottom: 5px;
					}
				}

				input {
					width: 100%;
					margin: 10px 0;
					height: 50px;
					border: #ccc solid 1px;
					padding-left: 35px;
					position: relative;
					border-radius: 30px;
					@each $name in 'nome' 'telefone' 'email' 'cep' {
						&[name="#{$name}"] {
							background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
							background-size: 26px;
						}
					}

					&:focus {
						border: $cor_02 solid 1px;
					}
				}

				button {
					background: $cor_03;
					font-size: 1.7em;
					font-weight: 700;
					color: #fff;
					padding: 10px 0;
					width: 60%;
					display: block;
					margin: 15px auto 0 auto;
					border-radius: 30px;
					@include animado();

					&:hover {
						background: $cor_11;
						text-decoration: none;
						padding: 10px 40px;
					}
				}

				img {
					position: absolute;
					right: 0;
					bottom: -40px;
					z-index: 10;
				}
			}

			.action-whatsaap {
				text-align: center;
				padding-top: 30px;
				@include media-breakpoint-down(sm) {
					padding-top: 0;
					margin-top: 30px;
					img {
						width: 80px;
					}
				}

				p {
					color: $cor_04;
					font-weight: 600;
					font-size: 1.5em;
				}
			}
		}
	}

	.plans {
		border-top: none;
		padding: 0;

		.container {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		h5 {
			@include media-breakpoint-down(sm) {
				margin-bottom: 100px;
			}
		}

		h4 {
			font-weight: 700;
			text-align: center;
			font-size: 2.5em;
			color: $cor_14;

			a {
				text-align: center;
				margin: 10px auto 0 auto;
				text-decoration: none;
				color: #fff;
				background: $cor_03;
				display: inline-block;
				padding: 5px 40px;
				border-radius: 100px;
				@include animado();

				&:hover {
					background: $cor_09;
				}

			}

		}

		.plan-info {
			display: none;
			min-height: auto;
			border-bottom: none;
		}

		.plan-button-info {
			padding-bottom: 20px;
			margin-top: -30px;

			a {
				display: block;

			}

			@include media-breakpoint-down(sm) {
				padding-bottom: 0;
				margin-top: -20px;
				a {
					margin-bottom: 0;
				}
			}
		}
	}

	.conar {
		color: #cccccc;
		padding-bottom: 20px;
		text-align: center;
	}

	footer {

		background: #eeedeb;

		.container {
			background: #eeedeb;
		}

		.phone-bottom {
			color: $cor_14;
			font-weight: 700;
			margin-bottom: 20px;
			font-size: 2.1em;
			text-align: center;

			a {
				color: $cor_14;
				display: block;
			}
		}

		.signature {
			border-top: 2px solid #fff;
			margin-top: 50px;
		}
	}
}

/* =======================================================================
   MODAL STEAP
   ======================================================================= */
.page-form-modal-step-1, .page-form-modal-step-2, .page-form-modal-step-3, .page-form-modal-step-4, .page-form-modal-step-5 {
	margin-top: 0;
	padding-top: 0;

	.fancybox-button {
		display: none;
	}
}

.modal-step-1 {
	background: $cor_01;
	padding-top: 10px;
	position: relative;
	height: 420px;
	max-width: 360px;

	.close-modal {
		position: absolute;
		top: 0;
		right: 0;
		color: #ffffff;
		font-size: 1.5em;
		display: block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		z-index: 9999;
		text-decoration: none;
		outline: none;
	}

	.title {
		text-align: center;
		color: #ffffff;

		h4 {
			font-size: 2em;
			font-weight: 600;
			margin-bottom: 30px;
		}
	}

	.modal-step-telesales, .modal-step-whats, .modal-step-already-customer, .modal-step-call-me {
		text-align: center;
		background: $cor_10;
		color: #ffffff;
		margin: 10px;
		padding: 20px 0;
		cursor: pointer;

		h3 {
			font-size: 1.2em;
			margin: 0;
			margin-top: 5px;
			font-weight: 700;
		}

		span[class^="icon"] {
			font-size: 3em;
		}

		a {
			color: #ffffff;
			font-size: .9em;
			outline: none;

			span {
				font-size: .6em;
			}
		}
	}
}

.modal-step-2 {
	background: $cor_10;
	padding-top: 10px;
	position: relative;
	height: 420px;
	max-width: 360px;

	.close-modal {
		position: absolute;
		top: 0;
		right: 0;
		color: #ffffff;
		font-size: 1.5em;
		display: block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		z-index: 9999;
		text-decoration: none;
		outline: none;
	}

	.title {
		text-align: center;
		color: #ffffff;


		h4 {
			font-size: 2.3em;
			font-weight: 600;
			background: $cor_11;
			margin: -10px -15px;
			padding: 10px 0;
			color: $cor_10;
		}

		p {
			margin-top: 20px;
			font-size: 1.2em;
		}

		img {
			margin-top: -20px;
			margin-bottom: -35px;
			position: relative;
			z-index: 10;
			width: 90%;
		}
	}

	.modal-step-back {
		text-align: center;

		a {
			background: $cor_09;
			color: #ffffff;
			padding: 5px 10px;
			display: block;
			font-size: 1.5em;
			margin: 0 30px;
			font-weight: 700;
		}

		padding-bottom: 10px;
	}

	form.form-contact {
		position: relative;
		padding: 10px;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		input {
			width: 100%;
			margin: 3px 0;
			height: 40px;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			border-radius: 30px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_02 solid 1px;
			}
		}

		button {
			margin: 5px auto;
			width: 100%;
			background: $cor_03;
			border-bottom: 4px solid darken($cor_03, 10);
			display: inline-block;
			padding: 3px 50px;
			font-size: 1.5em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			border-radius: 30px;
			text-decoration: none;
			@include animado();

			&:hover {
				border-radius: 0px;
			}
		}

		img {
			position: absolute;
			right: 0;
			bottom: -40px;
			z-index: 10;
		}
	}
}

.modal-step-3 {
	background: $cor_01;
	padding-top: 10px;
	position: relative;
	height: 420px;
	max-width: 360px;

	.close-modal {
		position: absolute;
		top: 0;
		right: 0;
		color: #ffffff;
		font-size: 1.5em;
		display: block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		z-index: 9999;
		text-decoration: none;
		outline: none;
	}

	.title {
		text-align: center;
		color: #ffffff;


		h4 {
			font-size: 2.3em;
			font-weight: 600;
			background: $cor_10;
			margin: -10px -15px;
			padding: 10px 0;
		}

		p {
			margin-top: 20px;
			font-size: 1.2em;
		}
	}

	img {
		position: relative;
		margin-bottom: -30px;
	}

	.telesales {
		background: #ffffff;
		padding: 15px 20px;
		text-align: center;
		font-size: 2.1em;
		font-weight: 700;

		span {
			color: $cor_09;
			vertical-align: middle;
		}

		a {
			color: $cor_09;

			span {
				font-size: 0.5em;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.modal-step-back {
		text-align: center;

		a {
			background: $cor_15;
			color: #ffffff;
			padding: 5px 10px;
			display: block;
			font-size: 1.5em;
			margin: 0 15px;
			font-weight: 700;
		}

		padding-bottom: 10px;
	}

}

.modal-step-4 {
	background: $cor_03;
	padding-top: 10px;
	position: relative;
	height: 420px;
	max-width: 360px;

	.close-modal {
		position: absolute;
		top: 0;
		right: 0;
		color: #ffffff;
		font-size: 1.5em;
		display: block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		z-index: 9999;
		text-decoration: none;
		outline: none;
	}

	.title {
		text-align: center;
		color: #ffffff;


		h4 {
			font-size: 2.3em;
			font-weight: 600;
			background: $cor_10;
			margin: -10px -15px;
			padding: 10px 0;
		}

		p {
			margin-top: 20px;
			font-size: 1.2em;
		}
	}

	img {
		position: relative;
		margin-bottom: -30px;
		margin-top: -10px;
	}

	.telesales {
		background: #ffffff;
		padding: 15px 20px;
		text-align: center;
		font-size: 2em;
		font-weight: 700;

		span {
			color: $cor_03;
			vertical-align: middle;
		}

		a {
			color: $cor_03;

			span {
				font-size: 0.5em;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.modal-step-back {
		text-align: center;

		a {
			background: $cor_09;
			color: #ffffff;
			padding: 5px 10px;
			display: block;
			font-size: 1.5em;
			margin: 0 15px;
			font-weight: 700;
		}

		padding-bottom: 10px;
	}

}

.modal-step-5 {
	background: $cor_09;
	padding-top: 10px;
	position: relative;
	height: 420px;
	max-width: 360px;

	p {
		font-size: 1em;
		padding: 0;
		margin: 0;
		color: #ffffff;
		text-align: center;
	}

	.close-modal {
		position: absolute;
		top: 0;
		right: 0;
		color: #ffffff;
		font-size: 1.5em;
		display: block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		z-index: 9999;
		text-decoration: none;
		outline: none;
	}

	.title {
		text-align: center;
		color: #ffffff;

		h4 {
			font-size: 2.3em;
			font-weight: 600;
		}

		p {
			font-size: 1.2em;
		}
	}

	.telesales {
		background: #ffffff;
		padding: 5px 0;
		text-align: center;
		font-size: 1.8em;
		font-weight: 700;

		span {
			color: $cor_09;
			vertical-align: middle;
		}

		a {
			color: $cor_09;

			span {
				font-size: 0.5em;
			}
		}

		margin: 10px -40px;
	}

	.modal-step-back {
		text-align: center;

		a {
			background: $cor_15;
			color: #ffffff;
			padding: 5px 10px;
			display: block;
			font-size: 1.5em;
			margin: 0 30px;
			font-weight: 700;
		}

		padding-bottom: 10px;
	}

	form.form-contact {
		position: relative;
		padding: 0 10px 10px 10px;
		@include placeholder {
			font-weight: 500;
			font-size: 18px;
			color: #cccccc;
		}

		input {
			width: 100%;
			margin: 5px 0;
			height: 40px;
			border: #ccc solid 1px;
			padding-left: 35px;
			position: relative;
			border-radius: 30px;
			@each $name in 'nome' 'telefone' 'email' 'cep' {
				&[name="#{$name}"] {
					background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
					background-size: 26px;
				}
			}

			&:focus {
				border: $cor_02 solid 1px;
			}
		}

		label {
			font-size: 1.3em;
			text-align: left;
			display: block;
			margin-left: 30px;

			input[type="checkbox"] {
				height: 20px;
				width: 20px;
				display: inline-block;
				vertical-align: bottom;
				margin: 0;
			}
		}

		button {
			margin: 5px auto;
			width: 100%;
			background: $cor_03;
			border-bottom: 4px solid darken($cor_03, 10);
			display: inline-block;
			padding: 5px 50px;
			font-size: 1.5em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			border-radius: 30px;
			text-decoration: none;
			@include animado();

			&:hover {
				border-radius: 0px;
			}
		}

	}
}

/* =======================================================================
   MODAL REDE CREDENCIADA STEAP
   ======================================================================= */
.page-form-modal-rede-step-1, .page-form-modal-rede-step-2, .page-form-modal-rede-step-3 {
	margin-top: 0;
	padding-top: 0;
}

.modal-step-rede-1, .modal-step-rede-2, .modal-step-rede-3 {
	background: $cor_09;
	padding-top: 10px;
	position: relative;
	height: 420px;

	h4 {
		font-size: 1.5em !important;
	}
}

/* =======================================================================
   WHATSAPP BUTTON
   ======================================================================= */
.whatsapp-button {
	padding: 30px 0;

	.base-whatsapp-button {
		box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.27);
		border-radius: 10em;
		padding: 10px 60px;

		a {
			font-size: 2em;
			font-weight: 600;
			color: $cor_14;
			text-decoration: none;

			span {
				font-size: 1.5em;
				background: #32BA46;
				background: -moz-linear-gradient(left, #32BA46 0%, #61E271 100%);
				background: -webkit-linear-gradient(left, #32BA46 0%, #61E271 100%);
				background: linear-gradient(to right, #32BA46 0%, #61E271 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#32BA46', endColorstr='#61E271', GradientType=1);
				color: #ffffff;
				border-radius: 100%;
				padding: 20px;
				vertical-align: middle;
				display: inline-block;
			}
		}

		@include media-breakpoint-down(sm) {
			padding: 0;
			padding-right: 5px;
			a {
				font-size: 3.8vw;

				span {
					font-size: 1.8em;
					padding: 10px;

				}
			}
		}
	}
}

/* =======================================================================
   FAQ
   ======================================================================= */
.faq-home {
	padding: 30px 0;
	border-top: lighten($cor_02, 10) solid 3px;

	h2 {
		display: block;
		position: relative;
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		margin-bottom: 30px;

		span {
			color: $cor_text;
			display: block;
		}

		&:before {
			content: '';
			position: absolute;
			top: 30px;
			left: -50px;
			border-top: 6px solid $cor_13;
			height: 10px;
			width: 40px;
		}
	}

	.faq-list {
		h3 {
			font-size: 1.5em;
			font-weight: 600;

			a {
				color: $cor_01;
				@include animado();

				&:hover {
					color: $cor_13;
				}
			}
		}
	}

	a#btn-all-faq {
		background: $cor_01;
		font-size: 1.6em;
		font-weight: 700;
		color: #fff;
		padding: 20px;
		display: block;
		margin: 20px auto 0 auto;
		text-align: center;
		@include media-breakpoint-up(sm) {
			max-width: 400px;
		}
		@include animado();

		&:hover {
			background: $cor_11;
			text-decoration: none;
		}
	}

}

.faq {
	padding: 40px 0 50px 0;
	border-top: 5px solid $cor_15;
	border-bottom: 5px solid $cor_15;

	.title-section {
		h2 {
			display: block;
			position: relative;
			font-weight: 700;
			color: $cor_14;
			font-size: 2em;
			margin-bottom: 30px;

			span {
				color: $cor_text;
				display: block;
			}

			&:before {
				content: '';
				position: absolute;
				top: 30px;
				left: -50px;
				border-top: 6px solid $cor_13;
				height: 10px;
				width: 40px;
			}
		}
	}

	.accordion {
		h2 {
			font-size: 1.5em;
		}

		a {
			color: $cor_13;
		}

		.btn {
			&.collapsed {
				img {
					transform: scaleY(-1);
				}
			}
		}

		.card-body {
			padding-top: 30px;
			padding-bottom: 30px;
			font-size: 1.2em;
			line-height: 1.4em;
			padding-left: 80px;
		}
	}

	a.all-ask {
		background: $cor_15;
		font-size: 1.6em;
		font-weight: 700;
		color: #fff;
		padding: 20px;
		display: block;
		margin: 0 auto 0 auto;
		border-radius: 80px;
		text-align: center;
		@include animado();

		&:hover {
			background: $cor_11;
			text-decoration: none;
		}
	}
}

/* =======================================================================
   FAQ CONTENT
   ======================================================================= */
.faq-content {
	padding: 40px 0;
	position: relative;

	h1 {
		font-weight: 700;
		color: $cor_14;
		font-size: 2em;
		padding-bottom: 20px;
	}

	p {
		font-size: 1.2em;
		line-height: 1.4em;
		padding-bottom: 20px;
		margin: 0;
	}

	a#btn-all-faq {
		background: $cor_01;
		font-size: 1.6em;
		font-weight: 700;
		color: #fff;
		padding: 20px;
		display: block;
		margin: 20px auto 0 auto;
		border-radius: 80px;
		text-align: center;
		@include media-breakpoint-up(sm) {
			max-width: 400px;
		}
		@include animado();

		&:hover {
			background: $cor_11;
			text-decoration: none;
		}
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			display: block;
			margin-bottom: 10px;

			a {
				padding: 10px 20px;
				text-decoration: none;
				color: #fff;
				display: block;
				font-size: 1em;
				background: $cor_13;
				border-radius: 30px;
				font-weight: 600;

				@include animado();

				&:hover {
					background: $cor_01;
					color: #fff;
				}

				&.active {
					background: $cor_01;
					color: #fff;
				}
			}
		}
	}

	.faq-content-base {
		position: relative;

	}

	.form-faq {
		position: sticky;
		left: 0;
		top: 120px;

		@include media-breakpoint-down(md) {
			display: none;
		}

		form {
			padding: 5px 20px 10px 20px;
			z-index: 10;
			border-radius: 4px;
			background: $cor_02;

			h4 {
				padding-top: 10px;
				color: $cor_10;
				font-size: 1.6em;
				text-align: center;

				a {
					color: #fff;
					display: block;
					font-size: 1.5em;
					padding: 5px 10px;
					text-decoration: none;
				}

			}

			input {
				width: 100%;
				display: block;
				margin: 15px auto 15px auto;
				height: 50px;
				border: #ccc solid 1px;
				padding-left: 35px;
				position: relative;
				border-radius: 30px;

				&:first-child {
					margin-top: 5px;
				}

				@each $name in 'nome' 'telefone' 'email' 'cep' {
					&[name="#{$name}"] {
						background: #fff url('../images/icon-#{$name}.svg') 4px center no-repeat;
						background-size: 26px;
					}
				}

				&:focus {
					border: $cor_02 solid 1px;
				}
			}

			button {
				background: $cor_03;
				font-size: 1.7em;
				font-weight: 700;
				color: #fff;
				padding: 10px 0;
				width: 70%;
				display: block;
				margin: 15px auto 15px auto;
				border-radius: 30px;
				@include animado();

				&:hover {
					color: $cor_10;
					background: $cor_11;
					text-decoration: none;
					padding: 10px 40px;
				}
			}
		}
	}

	.title-section {
		h1, h2 {
			display: block;
			position: relative;
			font-weight: 700;
			color: $cor_14;
			font-size: 2em;
			margin-bottom: 30px;

			span {
				color: $cor_text;
				display: block;
			}

			&:before {
				content: '';
				position: absolute;
				top: 30px;
				left: -50px;
				border-top: 6px solid $cor_13;
				height: 10px;
				width: 40px;
			}
		}
	}

	.search-container {
		padding: 20px 0 50px 0;

		input {
			width: 70%;
			margin: 0;
			height: 40px;
			border: #ccc solid 1px;
			padding-left: 15px;
			border-radius: 30px;
			display: inline-block;

			&:focus {
				border: $cor_02 solid 1px;
			}

			@include media-breakpoint-down(sm) {
				width: 60%;
			}
		}

		button {
			background: $cor_03;
			display: inline-block;
			font-size: 1.2em;
			font-weight: 700;
			color: #fff;
			height: 40px;
			padding: 5px;
			margin-top: -2px;
			border-radius: 30px;
			width: 29%;
			@include animado();
			@include media-breakpoint-down(sm) {
				width: 35%;
			}


			&:hover {
				color: $cor_10;
				background: $cor_11;
				text-decoration: none;
			}
		}
	}
}

.footer {
	background: #f36523;
	text-align: center;
	padding: 5px 0;
	-webkit-box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.3);
	bottom: 0;
	margin: 0 auto !important;
	width: 100%;

	.container {
		padding: 0 5px;
	}

	.row {
		border-radius: 50px;
	}

	h2 {
		margin: 0 5px;
		padding: 10px 0;
		background: #ffffff;
		font-weight: 700;
		font-size: 6vw;
		line-height: 6vw;
		border-radius: 50px;

		a {
			color: #333;


		}
	}


	small {
		color: #fff;
	}
}

.pulse-button {
	position: relative;
	box-shadow: 0 0 0 0 rgba(241, 98, 30, 0.7);
	background-color: #f36523;
	-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1)
}

.pulse-button:hover {
	-webkit-animation: none;
	-moz-animation: none;
	-ms-animation: none;
	animation: none
}

.pulse-yellow {
	border: 2px solid #ffd500;
	box-shadow: 0 0 0 0 rgba(255, 213, 0, 0.7);
	background-color: #ffd500;
	-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1)
}

.pulse-blue {
	border: 2px solid #00adef;
	box-shadow: 0 0 0 0 rgba(39, 119, 193, 0.7);
	background-color: #2777c1;
	-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1)
}

.pulse-green {
	border: 2px solid #4da735;
	box-shadow: 0 0 0 0 rgba(77, 167, 53, 0.64);
	background-color: #4da735;
	-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1)
}

@-webkit-keyframes pulse {
	to {
		box-shadow: 0 0 0 10px rgba(232, 76, 61, 0)
	}
}

@-moz-keyframes pulse {
	to {
		box-shadow: 0 0 0 10px rgba(232, 76, 61, 0)
	}
}

@-ms-keyframes pulse {
	to {
		box-shadow: 0 0 0 10px rgba(232, 76, 61, 0)
	}
}

@keyframes pulse {
	to {
		box-shadow: 0 0 0 10px rgba(232, 76, 61, 0)
	}
}


/* =======================================================================
   LGPD
   ======================================================================= */
.sas-box-lgpd-info {
	display: flex;
	position: fixed;
	z-index: 10;
	bottom: 16px;
	left: 16px;
	right: 16px;
	margin: auto;
	max-width: 1180px;
	min-height: 70px;
	box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #eeeeee;
	background-color: #fff;
	color: #000;
}

.sas-box-lgpd-info-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: inherit;
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
		justify-content: center;
	}
}

.sas-box-lgpd-info-text {
	vertical-align: middle;
	padding: 16px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 0;
	}
}

.sas-box-lgpd-info-button-wrapper {
	padding: 16px 16px 16px 0;
	@include media-breakpoint-down(sm) {
		padding: 10px;
	}
}

.sas-box-lgpd-info-accept-btn {
	background-color: $cor_07;
	padding: 20px 16px;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

/* =======================================================================
   PRIVACY POLICY
   ======================================================================= */
.privacy-policy {
	padding: 30px 0;

	h1 {
		text-align: left;
	}

	ul {
		li {
			padding-bottom: 10px;
		}
	}
}

.banner-black-friday {
	background: #000;
	font-weight: 600;
	color: #ffffff;
	text-align: center;
	@include media-breakpoint-down(sm) {
		padding: 20px 0;
	}

	h3 {
		color: $cor_02;
		font-weight: 600;
		font-size: 2em;
		margin-bottom: 10px;
	}

	p {
		font-size: 1.5em;
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			font-size: 1.3em;
			padding-bottom: 5px;
		}
	}

	span {
		display: inline-block;
		padding-bottom: 10px;
	}

	a {
		display: inline-block;
		padding: 10px 20px;
		font-size: 1.2em;
		font-weight: 700;
		text-align: center;
		color: #fff;
		border-radius: 50px;
		text-decoration: none;
		background: #F78E0F;
		border-bottom: 4px solid darken(#F78E0F, 15);
		@include animado();
		@include media-breakpoint-down(sm) {
			font-size: 7vw;
		}

		&:hover {
			border-bottom: 4px solid;
			border-radius: 0;
			border-bottom-color: darken(#F78E0F, 1);
		}
	}
}

/* =======================================================================
   E-COMMERCE
   ======================================================================= */
.page-ecommerce-01,
.page-ecommerce-02,
.page-ecommerce-03 {
	.whatsapp-float {
		display: none;
	}
}

.ecommerce {
	padding: 40px 0;

	.black-friday {
		margin-top: -50px;
		padding-top: 20px;
		padding-bottom: 15px;
		margin-bottom: 20px;
		$cor_neon1: #DFFFFF;
		background: #000424;

		.banner-text {
			h2 {
				font-size: 2.5em;
				font-weight: 700;
				margin-bottom: 10px;
				color: #F300A0;
				@include media-breakpoint-down(sm) {
					font-size: 8vw;
				}
			}

			p {
				font-size: 1em;
				line-height: 1.4em;
				font-weight: 600;
				margin-bottom: 10px;
				color: #fff;
				@include media-breakpoint-down(sm) {
					font-size: 5vw;
					font-weight: 600;
				}
			}
		}

		.text-banner {
			color: #ffffff;
			background: $cor_01;
			border: darken($cor_01, 10) solid 3px;
			border-left: none;
			border-right: none;
			text-align: center;

			p {
				padding: 20px 0;
				margin: 0;
				font-weight: 600;
				text-shadow: none;
			}
		}

		.action-banner {
			background: $cor_19;
			border-bottom: 4px solid darken($cor_19, 10);

			a {
				display: block;
				width: 100%;
				margin: 0 auto;
				padding: 10px 5px;
				font-size: 1.7em;
				font-weight: 700;
				text-align: center;
				color: #fff;
				text-decoration: none;

				@include animado();

				img {
					max-width: 30px;
					margin-right: 5px;
					vertical-align: sub;
				}

				&:hover {
					border-radius: 0;
				}
			}
		}

		.img-banner {
			width: 800px;
		}

		a.link-banner {
			color: #ffffff;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
			font-size: 1.3em;
			margin-top: 10px;
			font-weight: 700;
			background: #FF7100;
			display: inline-block;
			padding: 4px 30px;
			border-radius: 40px;
			margin-bottom: 30px;
			text-decoration: none;
			@include animado();

			&:hover {
				background: $cor_07;
				text-decoration: none;
				color: #ffffff;
			}

			@include media-breakpoint-down(sm) {
				font-size: 6vw;
				border-radius: 50px;
			}
		}

		@-webkit-keyframes neon_glow1 {

			from {
				text-shadow: 0 0 10px #fff,
				0 0 20px #fff,
				0 0 30px #fff,
				0 0 40px $cor_neon1,
				0 0 70px $cor_neon1,
				0 0 80px $cor_neon1,
				0 0 100px $cor_neon1,
				0 0 150px $cor_neon1;
			}
			to {
				text-shadow: 0 0 5px #fff,
				0 0 10px #fff,
				0 0 15px #fff,
				0 0 20px $cor_neon1,
				0 0 35px $cor_neon1,
				0 0 40px $cor_neon1,
				0 0 50px $cor_neon1,
				0 0 75px $cor_neon1;
			}
		}

		@-webkit-keyframes neon2 {
			from {
				text-shadow: 0 0 10px #fff,
				0 0 20px #fff,
				0 0 30px #fff,
				0 0 40px #228DFF,
				0 0 70px #228DFF,
				0 0 80px #228DFF,
				0 0 100px #228DFF,
				0 0 150px #228DFF;
			}
			to {
				text-shadow: 0 0 5px #fff,
				0 0 10px #fff,
				0 0 15px #fff,
				0 0 20px #228DFF,
				0 0 35px #228DFF,
				0 0 40px #228DFF,
				0 0 50px #228DFF,
				0 0 75px #228DFF;
			}
		}
	}

	.whatsapp-float {
		display: none;
	}

	// GERAL
	.base-alert {
		.alert {
			margin-top: 5px;
			padding: 3px 10px;
			font-size: 0.95em;

		}
	}

	.alert-danger {
		background: #f8d7da3d;
	}

	// TOPO
	.ecommerce-header {
		.dog-christmas {
			margin-top: -45px;
			margin-bottom: 10px;
			background-image: url("../images/banners/bg-natal.jpg");
			background-attachment: fixed;
			background-position: center -50px;
			background-repeat: no-repeat;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}

			h2 {
				color: #fff;
				font-size: 3em;
				font-weight: 700;
				margin-bottom: 20px;
				@include media-breakpoint-down(sm) {
					font-size: 8vw;
				}

			}

			h3 {
				color: #fff;
				font-size: 2.5em;
				line-height: 1.1em;
				font-weight: 600 !important;
				margin-bottom: 10px;
				@include media-breakpoint-down(sm) {
					font-size: 5vw;
					font-weight: 600;
				}
			}

			p {
				color: #fff;
				font-size: 2em;
				line-height: 1.1em;
				font-weight: 500;
				margin-bottom: 10px;
				@include media-breakpoint-down(sm) {
					font-size: 5vw;
					font-weight: 600;
				}
			}
		}

		.title {
			text-align: center;
			margin-bottom: 20px;
		}

		.steps {
			$brand-primary: $cor_03;
			$white: #fff;
			$grey-light: #ededed;

			%remain-steps {
				&:before {
					content: counter(stepNum);
					font-family: inherit;
					font-weight: 700;
				}

				&:after {
					background-color: $grey-light;
				}
			}

			.multi-steps {
				display: table;
				table-layout: fixed;
				width: 100%;

				> li {
					counter-increment: stepNum;
					text-align: center;
					display: table-cell;
					position: relative;
					color: $brand-primary;

					&:before {
						content: '\f00c';
						content: '\2713;';
						content: '\10003';
						content: '\10004';
						content: '\2713';
						display: block;
						margin: 0 auto 4px;
						background-color: $white;
						width: 36px;
						height: 36px;
						line-height: 32px;
						text-align: center;
						font-weight: bold;
						border: {
							width: 2px;
							style: solid;
							color: $brand-primary;
							radius: 50%;
						}
					}

					&:after {
						content: '';
						height: 2px;
						width: 100%;
						background-color: $brand-primary;
						position: absolute;
						top: 16px;
						left: 50%;
						z-index: -1;
					}

					&:last-child {
						&:after {
							display: none;
						}
					}

					&.is-active {
						@extend %remain-steps;

						&:before {
							background-color: $white;
							border-color: $brand-primary;
						}

						~ li {
							color: #808080;
							@extend %remain-steps;

							&:before {
								background-color: $grey-light;
								border-color: $grey-light;
							}
						}
					}
				}
			}
		}

		.info-add {
			text-align: center;
			margin: 20px 0;
			font-size: 1.3em;

			a {
				color: $cor_13;
				text-decoration: none;
				@include animado();

				&:hover {
					color: $cor_01;
				}
			}
		}

		.title-completion {
			text-align: center;
			margin: 20px 0;
			font-size: 2em;

			a {
				color: $cor_13;
				text-decoration: none;
				@include animado();

				&:hover {
					color: $cor_01;
				}
			}
		}

		.info-completion {
			color: $cor_13;
			text-align: center;
			margin: 20px 0;
			font-size: 1.4em;

			p {
				padding: 0;
				margin: 0;
			}

			a {
				color: $cor_01;
				text-decoration: none;
				@include animado();

				&:hover {
					color: $cor_01;
				}
			}

			@include media-breakpoint-down(sm) {
				br {
					display: none;
				}
			}
		}

		.step-back {
			text-align: right;

			a {
				display: inline-block;
				background: $cor_13;
				color: #ffffff;
				font-weight: 600;
				padding: 5px 20px;
				font-size: 1em;
				border-radius: 30px;
				text-decoration: none;
				@include animado();

				&:hover {
					background: darken($cor_13, 10);
				}
			}
		}
	}

	// CONTEÚDO
	.ecommerce-content {
		padding: 30px 0;
		font-size: 1.2em;

		// PÁGINA PLANOS
		form#plans {

			@include placeholder {
				font-weight: 500;
				color: $cor_text;
				font-size: 1.1em;
			}

			label {
				font-weight: 600;
			}

			input {
				padding-top: 15px;
				padding-bottom: 15px;
				margin-bottom: 10px;
				border-color: darken($cor_text, 10);

			}

			.form-text {
				margin-top: -10px;
				margin-bottom: 10px;
			}

			select {
				color: $cor_text;
				padding-top: 15px;
				padding-bottom: 15px;
				margin-bottom: 10px;
				border-color: darken($cor_text, 10);

				option {
					color: $cor_text;

					&:first-child {
						color: lighten($cor_text, 20);
					}
				}
			}

			.form-text {
				font-size: 0.9em;
			}

			.pre-registration {
				padding-bottom: 50px;
			}

			.row .col-xl-9 .row {
				padding-bottom: 20px;

				&.container-alert {
					padding-bottom: 0;
				}

				&:first-child {
					.remove-plan {
						visibility: hidden;
					}
				}
			}

			.remove-plan {
				span {
					display: none;
				}

				@include media-breakpoint-down(sm) {
					img {
						display: none;
					}
					margin-top: 20px;
					display: block;
					background: #f44336;
					color: $white;
					font-weight: 600;
					padding: 8px 15px;
					font-size: 1.2em;
					border-radius: 30px;
					text-decoration: none;
					text-align: center;
					@include animado();
					&:hover {
						background: darken($cor_03, 10);
					}
					span {
						display: block;
					}
				}

			}

			#add-pet {
				display: inline-block;
				background: $cor_03;
				color: #ffffff;
				font-weight: 600;
				padding: 8px 15px;
				font-size: 1.2em;
				text-decoration: none;
				@include animado();

				&:hover {
					background: darken($cor_03, 10);
				}
			}

			.payment-methods {
				padding: 60px 0 10px 0;

				h4 {
					text-align: center;
				}

				h5 {
					margin-bottom: 20px;
					text-align: center;
				}

				label {
					br {
						display: none;
					}
				}

				@include media-breakpoint-down(sm) {
					text-align: center;
					label {
						margin-bottom: 20px;

						br {
							display: block;
						}
					}
				}
			}


			.quotation-info {
				$line-height: 10px;
				border: solid 1px lighten($cor_text, 20);
				border-radius: 3px;
				padding: 20px;
				text-align: right;


				h3 {
					margin-bottom: 15px;
					@include media-breakpoint-down(sm) {
						text-align: center;
					}
				}

				.quotation-plan {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					margin: 0 -20px;
					padding: 10px;

					&:nth-of-type(even) {
						background: #f3f3f3;
					}

					strong {
						text-align: left;

						&:nth-of-type(even) {
							text-align: right;
						}

						min-width: 50%;
						font-weight: 500;
						font-size: 0.9em;
					}
				}

				.quotation-discount {
					font-size: 0.9em;

					strong {
						display: block;
						font-weight: 600;
					}

					span {
						display: block;
						padding-bottom: 10px;

						small {
							display: block;
							font-size: 0.7em;
							line-height: 0.7em;
						}
					}

					padding-bottom: $line-height;
				}

				.quotation-subtotal {
					font-size: 1.2em;
					padding-top: $line-height;

					strong {
						display: block;
						padding-bottom: 5px;
						font-weight: 600;
					}

					em {
						font-style: normal;
						font-size: 0.7em;
						padding-left: 2px;
					}

					span {
						display: block;

						&.spotlight {
							font-size: 0.9em;
							color: #fff;
							// border-radius: 50px;
							text-decoration: none;
							background: $cor_18;
							border-bottom: 4px solid darken($cor_18, 10);
							text-align: center;
							padding: 0 5px;
							margin: 5px 0;
						}
					}


					padding-bottom: $line-height;
				}
			}

			.quotation-finalize {
				padding: 20px 0;
				text-align: right;

				button {
					display: inline-block;
					background: $cor_13;
					color: #ffffff;
					font-weight: 600;
					padding: 8px 25px;
					font-size: 1.5em;
					text-decoration: none;
					@include animado();

					&:hover {
						background: darken($cor_13, 10);
					}
				}
			}
		}

		// PÁGINA CADASTRO
		form#register {
			@include placeholder {
				font-weight: 500;
				color: lighten($cor_text, 15)
			}


			.row {
				padding-bottom: 15px;
			}

			label {
				font-weight: 600;
			}

			input {
			}

			select {
				color: $cor_text;

				option {
					color: $cor_text;

					&:first-child {
						color: lighten($cor_text, 20);
					}
				}
			}

			.form-text {
				font-size: 0.9em;
			}

			.register-finalize {
				padding: 20px 0;
				text-align: right;

				button {
					display: inline-block;
					background: $cor_13;
					color: #ffffff;
					font-weight: 600;
					padding: 8px 25px;
					font-size: 1.5em;
					text-decoration: none;
					@include animado();

					&:hover {
						background: darken($cor_13, 10);
					}
				}
			}

			@include media-breakpoint-down(sm) {
				.form-group {
					padding-bottom: 15px;
				}
				.row {
					padding-bottom: 0;
				}
			}
		}

		// PÁGINA FINAL
		.complete-registration {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 20px;
			border: solid 1px lighten($cor_text, 20);
			border-radius: 3px;

			h3 {
				text-align: center;
				margin-bottom: 15px;
				width: 100%;
			}

			strong {
				font-weight: 600;
			}

			span {

				&:nth-of-type(even) {
					text-align: right;
				}

				font-weight: 500;
				font-size: 1em;
				padding: 0 10px 0 10px;
				@include media-breakpoint-down(sm) {
					width: 100%;
					text-align: left !important;
				}
			}
		}

		.completion-info {
			$line-height: 10px;
			border: solid 1px lighten($cor_text, 20);
			border-radius: 3px;
			padding: 20px;
			text-align: right;

			h3 {
				text-align: center;
				margin-bottom: 15px;
			}


			.completion-plan {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				padding-bottom: 13px;

				&:nth-of-type(even) {
					background: #f3f3f3;
				}

				strong {
					text-align: left;

					&:nth-of-type(even) {
						text-align: right;
					}

					min-width: 50%;
					font-weight: 500;
					font-size: 1em;
				}
			}

			.completion-discount {
				font-size: 0.9em;

				strong {
					display: block;
					font-weight: 600;
				}

				span {
					display: block;
					padding-bottom: 10px;

					small {
						display: block;
						font-size: 0.7em;
						line-height: 0.7em;
					}
				}

				padding-bottom: $line-height;
			}

			.completion-subtotal {
				font-size: 1.2em;
				padding-top: $line-height;

				strong {
					display: block;
					padding-bottom: 5px;
					font-weight: 600;
				}

				em {
					font-style: normal;
					font-size: 0.7em;
					padding-left: 2px;
				}

				span {
					display: block;

					&.spotlight {
						font-size: 0.9em;
						color: #fff;
						// border-radius: 50px;
						text-decoration: none;
						background: $cor_18;
						border-bottom: 4px solid darken($cor_18, 10);
						text-align: center;
						padding: 0 5px;
						margin: 5px 0;
					}
				}


				padding-bottom: $line-height;
			}
		}


		.back-to-home {
			text-align: center;

			a {
				display: inline-block;
				background: $cor_13;
				color: #ffffff;
				font-weight: 600;
				padding: 8px 25px;
				margin-top: 20px;
				font-size: 1.5em;
				text-decoration: none;
				@include animado();

				&:hover {
					background: darken($cor_13, 10);
				}
			}
		}

		.more-info {
			text-align: center;
			padding: 10px;

			a {
				color: $cor_13;
				text-decoration: none;
				@include animado();

				&:hover {
					color: $cor_01;
				}
			}
		}
	}


}

.inframe-detalhes-planos {
	padding: 0;
	background: #ffffff;
}

.inframe-detalhes-planos .container {
	padding: 0 20px;
}

#detalhes-planos {
}

#detalhes-planos .table-plans {
}

#detalhes-planos .table-plans .container {
}

.fancybox-slide {
	padding: 20px;
}

.fancybox-slide--iframe .fancybox-content {
	width: 800px;
	height: 600px;
	max-width: 100%;
	max-height: 90%;
	margin: 0;
}


/* =======================================================================
   ACCREDITED NETWORK
   ======================================================================= */
.accredited-network-page {
	padding: 40px 0;

	h2 {
		text-align: center;
		display: block;
		font-weight: 700;
		color: #00adef;
		font-size: 3em;
	}

	form {
		button {
			display: inline-block;
			padding: 5px 30px;
			font-size: 1.5em;
			font-weight: 700;
			text-align: center;
			color: #fff;
			text-decoration: none;
			background: $cor_02;
			border-bottom: 4px solid darken($cor_02, 10);
			@include animado();

			&:hover {
				border-radius: 0;
			}
		}
	}

	.results {
		padding: 40px 0;

		table {
			border: 1px solid #ccc;
			border-collapse: collapse;
			margin: 0;
			padding: 0;
			width: 100%;
			color: darken($cor_text, 10);
			// table-layout: fixed;
			h3 {
				font-size: 1.3em;
			}

			span {
				display: inline-block;

				&.telefone-secundario {
					padding-left: 10px;
				}

				&.como-chegar {
					display: block;
					padding: 8px 0;

					a {
						display: inline-block;
						padding: 5px 15px;
						font-size: 1em;
						font-weight: 700;
						text-align: center;
						color: #fff;
						text-decoration: none;
						background: $cor_18;
						border-bottom: 4px solid darken($cor_18, 10);
						@include animado();

						&:hover {
							border-radius: 0;
						}
					}
				}

				a {
					display: inline-block;
					padding: 5px 15px;
					font-size: 1em;
					font-weight: 700;
					text-align: center;
					color: #fff;
					text-decoration: none;
					background: $cor_02;
					border-bottom: 4px solid darken($cor_02, 10);
					@include animado();

					&:hover {
						border-radius: 0;
					}
				}
			}
		}

		table caption {
			font-size: 1.5em;
			margin: .5em 0 .75em;
		}

		table tr {
			border: 1px solid #ddd;
			padding: .35em;
		}

		table th,
		table td {
			padding: .625em;
			text-align: center;

			@include media-breakpoint-up(md) {
				&:nth-child(1) {
					width: 30%;
				}

				&:nth-child(2) {
					width: 10%;
				}

				&:nth-child(3) {
					width: 20%;
				}

				&:nth-child(4) {
					width: 20%;
				}

				&:nth-child(5) {
					width: 10%;
				}

				&:nth-child(6) {
					width: 10%;
				}
			}

			ul {
				list-style: none;
				padding: 0;

				li {
				}
			}
		}

		table th {
			font-size: .85em;
			letter-spacing: .1em;
			text-transform: uppercase;
		}

		@media screen and (max-width: 600px) {
			table {
				border: 0;
			}

			table caption {
				font-size: 1.3em;
			}

			table thead {
				border: none;
				clip: rect(0 0 0 0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;
			}

			table tr {
				border-bottom: 3px solid #ddd;
				display: block;
				margin-bottom: .625em;
			}

			table td {
				border-bottom: 1px solid #ddd;
				display: block;
				font-size: .8em;
				text-align: right;

			}

			table td::before {
				/*
				* aria-label has no advantage, it won't be read inside a table
				content: attr(aria-label);
				*/
				content: attr(data-label);
				float: left;
				font-weight: bold;
				text-transform: uppercase;
			}

			table td:last-child {
				border-bottom: 0;
			}
		}
	}
}


#whatsapp-slide {
	display: none;

	.whatsapp-form {
		border: 2px solid #008a7c;
		margin-left: 20px;
		background: url('../images/bg-whatsapp.jpg') repeat;
		border-radius: 10px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
		overflow: hidden;

		h2 {
			font-size: 1.5em;
			text-align: center;
			padding: 5px 0;
			background: #008a7c;
			color: #ffffff;
		}

		form {
			padding: 10px;

			.message {
				background: #fff;
				border-radius: 0px 5px 5px 5px;
				margin-bottom: 10px;

				&:after {
					border-width: 0px 10px 10px 0;
					border-color: transparent #fff transparent transparent;
					top: 0;
					left: -10px;
				}

				input {
					border: 1px #008a7c solid;
					border-radius: 0px 5px 5px 5px;
					width: 100%;
					padding: 5px 5px 5px 10px;
				}
			}

			button {
				background: #008a7c;
				color: #ffffff;
				padding: 5px 15px;
				border-radius: 20px;
				position: relative;
				z-index: 1;
				font-weight: 600;
				display: inline-block;
				text-align: center;

				img {
					width: 20px;
					margin-left: 10px;
					margin-top: -3px;
				}
			}


		}
	}
}
